<template>
  <v-app :class="{ ovlocked: $store.state.ovLocked }">
    <div :class="[{ fadeIn: user != null }]" class="navWrapper">
      <v-app-bar color="primary" height="72" class="px-6" prominent>
        <div class="container mainNavContainer fyCenter relative mx-auto">
          <v-row>
            <v-col class="fyCenter" cols="6" lg="4">
              <router-link @click="drawer = false" to="/dashboard">
                <mpm-logo type="full" />
              </router-link>
            </v-col>
            <v-col class="fxEnd" cols="6" lg="8">
              <div v-if="this.$route.name == 'Dashboard'" class="fyCenter mr-3 h-full">
                <quick-nav />
              </div>
              <div class="pr-6 md:pr-16 h-full fyCenter" v-if="this.$route.name == 'Projekt'">
                <v-btn
                  color="var(--cHeading)"
                  class="mr-4 md:mr-8"
                  @click="(drawer = false), (prChooseOv = true)"
                  variant="tonal"
                >
                  <v-icon class="md:mr-2" icon="md:assessment"></v-icon>
                  <span class="hidden md:inline-block">Projekte</span>
                </v-btn>
                <v-btn color="var(--cHeading)" class="text-white safeBtn" variant="flat">
                  <v-icon class="md:mr-2" icon="mdi-content-save"></v-icon
                  ><span class="hidden md:inline-block">Speichern</span>
                </v-btn>
              </div>
              <div class="fyCenter h-full deskUtilNav" v-if="$route.name == 'Dashboard'">
                <div v-if="user" class="mr-1 md:mr-4 openTaskWrapper">
                  <open-task-comp :today="$moment().format('YYYY-MM-DD')" :uid="user.id" />
                </div>
                <div v-if="$store.state.dViewEvents.length" class="mr-1 md:mr-4 dailyViewWrapper">
                  <daily-view :tasks="$store.state.dViewEvents" />
                </div>
                <div class="mr-4 md:mr-4 dailyViewWrapper">
                  <tasks-filter :sDate="$store.state.tasksConfig.sDate" :eDate="$store.state.tasksConfig.eDate" />
                </div>
              </div>
              <div class="mainNavWrap h-full fxEnd fyCenter">
                <v-btn @click="drawer = !drawer" color="var(--cMPM)" class="text-white safeBtn" variant="flat">
                  <v-icon icon="md:menu"></v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-app-bar>
    </div>
    <div class="bg-gradient-to-r from-violet-700 min-h-screen relative to-bg-pmatch">
      <router-view />
    </div>
    <v-dialog class="overlayNavModal" v-model="drawer">
      <v-card>
        <v-row no-gutters>
          <v-col cols="12" md="6">
            <v-list class="">
              <router-link @click="drawer = false" to="/dashboard">
                <v-list-item class="py-4" prepend-icon="md:task_alt" title="Tasks" value=""></v-list-item>
              </router-link>
              <hr class="opacity-20" />
              <v-list-item
                @click="(drawer = false), (prChooseOv = true)"
                class="py-4"
                prepend-icon="md:assessment"
                title="Projekte"
                value=""
              ></v-list-item>
              <hr class="opacity-20" />
              <router-link @click="drawer = false" to="/finances">
                <v-list-item class="py-4" prepend-icon="mdi-cash" title="Finanzen" value=""></v-list-item>
              </router-link>
              <hr class="opacity-20" />
              <router-link @click="drawer = false" to="/whishlists">
                <v-list-item class="py-4" prepend-icon="mdi-ballot" title="Wunschlisten" value=""></v-list-item>
              </router-link>
              <hr class="opacity-20" />
            </v-list>
          </v-col>
          <v-col cols="12" md="6">
            <v-list class="">
              <router-link @click="drawer = false" to="/documents">
                <v-list-item
                  class="py-4"
                  prepend-icon="mdi-file-document-outline"
                  title="Dokumentationen"
                  value=""
                ></v-list-item>
              </router-link>
              <hr class="opacity-20" />
              <router-link @click="drawer = false" to="/birthdays">
                <v-list-item class="py-4" prepend-icon="md:cake" title="Geburtstage" value=""></v-list-item>
                <hr class="opacity-20" />
              </router-link>
              <v-list-item class="py-4" prepend-icon="mdi-account-check" title="Wochenziele" value=""></v-list-item>
              <hr class="opacity-20" />
              <v-list-item
                class="py-4"
                @click="signOut()"
                prepend-icon="md:logout"
                title="Logout"
                value=""
              ></v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <project-choose :open="prChooseOv" @close="prChooseOv = false" />
  </v-app>
</template>

<script>
import MpmLogo from "./components/mpmLogo.vue";
import OpenTaskComp from "./components/openTaskComp.vue";
import ProjectChoose from "./components/projectChoose.vue";
import QuickNav from "./components/quickNav.vue";

export default {
  components: { MpmLogo, OpenTaskComp, QuickNav, ProjectChoose },
  name: "App",
  data: () => ({
    drawer: false,
    group: null,
    isLoggedIn: false,
    prChooseOv: false,
    wWidth: document.documentElement.clientWidth,
    //
  }),
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  mounted() {
    window.addEventListener("resize", this.getDimensions);
    this.getDimensions;
  },
  unmounted() {
    window.removeEventListener("resize", this.getDimensions);
  },
  methods: {
    getDimensions() {
      this.wWidth = document.documentElement.clientWidth;
      if (this.wWidth > 768) {
        this.$store.dispatch("responseSizing", "mobile");
      } else {
        this.$store.dispatch("responseSizing", "desktop");
      }
    },
    signOut() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push({
          path: "/",
        });
      });
    },
  },
};
</script>
