
const wishItemScheme = () => {
    let ntS = {
        id: false,
        Name: '',
        Cost: '',
        Links: '',
        Desc: '',
        Type: 'need',
        Prio: 5
    };
return  ntS;

}
export default wishItemScheme;