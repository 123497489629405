import { createStore } from "vuex";
import axios from "axios";
import moment from "moment";

export default createStore({
  state: {
    user: null,
    dViewEvents: [],
    tasksConfig: {
      sDate: moment().format("YYYY-MM-DD"),
      eDate: moment().add("7", "days").format("YYYY-MM-DD"),
    },
    theme: {
      btnSmallEdit: "small",
    },
    // uploadUrl: 'https://mpmback.devjb.de',
    // uploadUrl: "http://localhost:1337",
    uploadUrl: "https://mpmbackv3.devjb.de",
    cProject: {
      hasChanged: false,
    },
    ovLocked: false,
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      localStorage.setItem("user", JSON.stringify(userData));
      axios.defaults.headers.common["Authorization"] = `Bearer ${userData.token}`;
      state.user = userData;
    },
    SET_DAY_EVENTS(state, events) {
      state.dViewEvents = events;
    },
    SET_TASKS_CONFIG(state, data) {
      state.tasksConfig.sDate = data.sDate;
      state.tasksConfig.eDate = data.eDate;
    },
    SET_RESP_CONFIG(state, data) {
      if (data == "mobile") {
        state.theme.btnSmallEdit = "default";
      } else {
        state.theme.btnSmallEdit = "small";
      }
    },
    LOGOUT() {
      localStorage.removeItem("user");
      localStorage.removeItem("jwt");
      location.reload();
    },
    SET_OV_LOCKED(state, type) {
      state.ovLocked = type;
    },
  },
  actions: {
    login({ commit }, credentials) {
      return axios.post("https://mpmbackv3.devjb.de/" + "api/auth/local", credentials).then(({ data }) => {
        commit("SET_USER_DATA", data);
        return data;
      });
    },
    setDayEvents({ commit }, data) {
      commit("SET_DAY_EVENTS", data);
    },
    setOvLocked({ commit }, data) {
      commit("SET_OV_LOCKED", data);
    },
    updateTaskConfig({ commit }, data) {
      commit("SET_TASKS_CONFIG", data);
    },
    responseSizing({ commit }, data) {
      commit("SET_RESP_CONFIG", data);
    },
    logout({ commit }) {
      commit("LOGOUT");
    },
  },
});

// export the store
