<template>
<div class="projectCompWrapper">
    <v-dialog class="overlayCont" scrollable v-model="showD">
        <div v-if="pr != null">
            <div class="ovCard">
                <div class="ovCardTitleWrapper">
                    <div class="">
                        <div class="ovTitle">
                            Projektinfo</div>
                    </div>
                    <div class="editDialogWrapper fyCenter py-3">
                        <div class="closeBtnWrapper">
                            <v-btn @click="closeCurrentProject()" size="small" color="var(--cMPM)" variant="outlined" prepend-icon="mdi-close-circle"><span class="hidden md:inline-block">Verwerfen</span></v-btn>
                        </div>
                        <div class="safeBtnWrapper ml-8">
                            <v-btn @click="safeFull()" size="small" color="var(--cMPM)" prepend-icon="mdi-content-save"><span class="hidden md:inline-block"> Speichern</span></v-btn>
                        </div>
                    </div>
                </div>
                <v-divider class="mb-4"></v-divider>
                <div class="px-6">
                    <v-row>
                        <v-col cols="12" class="pb-0">
                            <div class="currProjectInd">
                                {{prWtLabel}}
                            </div>
                            <div class="bigTitle">
                                {{pr.attributes.Name}}
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="mt-6 pb-6">
                            <v-card class="bg-white" flat>
                                <v-tabs v-model="tab" background-color="transparent">
                                    <v-tab class="bg-gray-100 md:px-4 font-10 px-1  md:text-xs" value="one">Informationen</v-tab>
                                    <v-tab class="bg-gray-100 md:px-4 font-10 px-1  md:text-xs" value="two">Accounts</v-tab>
                                    <v-tab class="bg-gray-100 md:px-4 font-10 px-1  md:text-xs" value="three">Ansprechpartner</v-tab>
                                </v-tabs>

                                <v-card-text class="pb-4 px-0">
                                    <v-window v-model="tab">
                                        <v-window-item value="one">
                                            <div class="currProjectInd mb-2">
                                                Allgemeine Informationen
                                            </div>
                                            <div class="pt-2">
                                                <EditorMPM v-model="Infos" />
                                            </div>
                                        </v-window-item>
                                        <v-window-item value="two">
                                            <div class="currProjectInd mb-2">
                                                Accountinformationen
                                            </div>
                                            <div class="pt-2">
                                                <EditorMPM v-model="Accs" />
                                            </div>
                                        </v-window-item>
                                        <v-window-item value="three">
                                            <div class="currProjectInd mb-2">
                                                Ansprechpartner
                                            </div>
                                            <v-list class="bg-white">
                                                <v-list-item v-for="(ap, i) in aPs" :key="i" class="mb-4 opacity-100 apList bg-gray-100" active-color="primary" variant="plain">
                                                    <template v-slot:append>
                                                        <v-btn v-show="panel[i] === true" variant="plain" @click="panel[i] = false" icon="mdi-minus"></v-btn>
                                                        <v-btn v-show="panel[i] === false" variant="plain" @click="panel[i] = true" icon="mdi-pencil"></v-btn>
                                                    </template>
                                                    <v-list-item-title>
                                                        <v-row class="py-3 fyCenter text-xs ml-2 md:ml-4">
                                                            <v-col cols="12" sm="4" class="fyCenter">
                                                                <div v-show="panel[i] === false" class="fyCenter">
                                                                    <v-icon icon="mdi-account" class="mr-3"></v-icon>
                                                                    <span>{{ap.Name}}</span>
                                                                </div>
                                                                <v-text-field v-show="panel[i] === true" v-model="ap.Name" hide-details label="Name"></v-text-field>
                                                            </v-col>
                                                            <v-divider class="-mx-4 sm:hidden my-2 md:-mx-6"></v-divider>

                                                            <v-col cols="12" sm="4" class="fyCenter">
                                                                <div v-show="panel[i] === false" class="fyCenter">
                                                                    <v-icon icon="mdi-email" class="mr-3"></v-icon>
                                                                    <span>{{ap.Email}}</span>
                                                                </div>
                                                                <v-text-field v-show="panel[i] === true" v-model="ap.Email" hide-details label="Email"></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" sm="4">
                                                                <div v-show="panel[i] === false" class="fyCenter">
                                                                    <v-icon icon="mdi-phone" class="mr-3"></v-icon>
                                                                    <span>{{ap.Telefon}}</span>
                                                                </div>
                                                                <v-text-field v-show="panel[i] === true" v-model="ap.Telefon" hide-details label="Telefon"></v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                        <div v-show="panel[i] === true" class="w-full py-4 mt-2 fxEnd">
                                                            <v-btn @click="deleteApp(i)" variant="plain" class="opacity-100 font-bold mr-6" color="rgb(220 38 38)" size="small">
                                                                <v-icon start icon="mdi-trash-can"></v-icon>
                                                                löschen
                                                            </v-btn>
                                                            <v-btn @click="updateApp(i, { 'Ansprechpartner': aPs }, false)" variant="plain" class="opacity-100 font-bold" color="var(--cMPM)" size="small">
                                                                <v-icon start icon="mdi-content-save"></v-icon>
                                                                Speichern
                                                            </v-btn>
                                                        </div>
                                                    </v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                            <div class="py-2 fxEnd">
                                                <v-btn :disabled="newOn" @click="addApp">Hinzufügen</v-btn>
                                            </div>
                                        </v-window-item>
                                    </v-window>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </div>
    </v-dialog>
    <v-dialog class="overlayCont centered" scrollable v-model="showWarn">
        <div class="ovCard xs-small">
            <div class="w-full text-center p-4">
                Alle Änderungen verwerfen?
            </div>
            <div class="fCenter w-full">
                <div>
                    <v-btn class="mb-4 block w-44" @click="withDrawCurrent">Ja</v-btn>
                    <v-btn variant="outlined" class="block w-44" @click="showWarn = false">zurück</v-btn>
                </div>
            </div>
        </div>
    </v-dialog>
</div>
</template>

<script>
import { getProjectByID } from '@/graphql/queries'
import { updateProject } from '@/graphql/mutations'
import workTypeLabel from '@/composables/getWorkTypeLabel';
import EditorMPM from './EditorMPM.vue';

export default {
    components: { EditorMPM },
    props: {
        id: {
            type: String,
        },
        showProject: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            pid: this.id,
            pr: null,
            aPs: [],
            prWtLabel: null,
            Infos: '',
            Accs: '',
            tab: null,
            panel: [],
            newAp: {
                Name: '',
                Email: '',
                Telefon: ''
            },
            newOn: false,
            showD: this.showProject,
            showWarn: false

        }
    },
    apollo: {
        project: {
            query: getProjectByID,
            variables() {
                return {
                    "id": parseInt(this.pid),
                }
            },
            context: {
                headers: {
                    "Authorization": 'Bearer ' + localStorage.getItem('jwt')
                },
            },
            result(res) {
                this.pr = { ...res.data.project.data };
                this.aPs = [...this.pr.attributes.Ansprechpartner];
                this.Infos = this.pr.attributes.Informationen;
                this.Accs = this.pr.attributes.Accounts;
                this.aPs.forEach((a, index) => {
                    this.aPs[index] = {
                        Name: a.Name,
                        Email: a.Email,
                        Telefon: a.Telefon
                    }
                    this.panel[index] = false;
                });
                this.prWtLabel = workTypeLabel(this.pr.attributes.WorkType);

            },
            error(graphQLErrors) {
                console.log('errors', graphQLErrors)
            }

        },
    },
    methods: {
        closeCurrentProject() {
            this.showWarn = true;
        },
        withDrawCurrent() {
            this.showWarn = false;
            this.$emit('close');
        },
        addApp() {
            this.aPs.push(this.newAp);
            this.panel.push(true);
            this.newOn = true;
            console.log(this.panel);
            // this.newAp = {
            //     Name: '',
            //     Email: '',
            //     Telefon: ''
            // };
            // let i = this.aPs.length - 1;
            // let data = {
            //     "Ansprechpartner": this.aPs
            // }
            // this.updateApp(i, data);
        },
        safeFull() {
            console.log('hi');
            let data = {
                "Informationen": this.Infos,
                "Accounts": this.Accs
            }
            this.updateApp(null, data);
        },
        deleteApp(i) {
            this.aPs.splice(i, 1);
            let data = {
                "Ansprechpartner": this.aPs
            }
            this.updateApp(null, data, false);
        },
        updateApp(i = null, data, close = true) {

            let vars = {
                "ID": parseInt(this.pid),
                "data": data
            }
            this.$apollo.mutate({
                    mutation: updateProject,
                    context: {
                        headers: {
                            "Authorization": 'Bearer ' + this.$store.state.user.jwt
                        },
                    },
                    variables: vars

                })
                .then((res) => {
                    if (i !== null) {
                        this.panel.splice(i, 1);
                    } else {
                        this.panel[i] = false;
                    }
                    this.newOn = false;
                    if (close) {
                        this.$emit('close');
                    }
                    console.log(i);
                    console.log(this.panel);
                    console.log(res);
                });

        }

    }
}
</script>

<style>
.ProseMirror {
    height: 100%;
    padding: 1rem;
}

.ProseMirror-focused {
    outline: none;
    border: none;
}

@media (max-width: 768px) {
    .editApBtn {
        height: 36px;
        min-height: 36px;
    }

    .editDialogWrapper .v-btn__prepend {
        margin-right: 0;
    }

    .editApBtn .v-expansion-panel-title__icon {
        display: none;
    }

}
</style>
