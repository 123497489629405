<template>
<div class="mr-0 md:mr-16">
    <v-menu location="bottom end">
        <template v-slot:activator="{ props }">
            <v-btn variant="tonal" class="" color="var(--cMPM)" v-bind="props">
                <v-icon icon="md:layers" />
                <span class="hidden md:inline-block">Ansichten </span>
            </v-btn>
        </template>
        <v-list>
            <v-list-item>
                <div v-if="$store.state.dViewEvents.length" class="mr-1 md:mr-4 dailyViewWrapper">
                    <v-btn class="fyCenter" variant="text" @click="openView('dayview')" :size="$store.state.theme.btnSmallEdit" color="var(--cMPM)">
                        <v-icon icon="md:calendar_view_day" class="mr-3"></v-icon> Tagesansicht
                    </v-btn>
                </div>
            </v-list-item>
            <v-list-item>
                <v-btn variant="text" @click="openView('filter')" :size="$store.state.theme.btnSmallEdit" color="var(--cMPM)">
                    <v-icon icon="mdi-filter"></v-icon> Filter
                </v-btn>
            </v-list-item>
        </v-list>
    </v-menu>
    <v-dialog class="" fullscreen transition="dialog-bottom-transition" :scrim="false" v-model="tViews.dayview">
        <div class="bg-white h-full">
            <div class="ovCardTitleWrapper">
                <div class="">
                    <div class="ovTitle">
                        Tagesübersicht</div>
                </div>
                <div class="closeBtnWrapper">
                    <v-btn @click="openView('')" color="var(--cMPM)" variant="text" icon="mdi-close-circle"></v-btn>
                </div>
            </div>
            <v-divider class="ovTitleDivider"></v-divider>
            <daily-view />

        </div>
    </v-dialog>
    <v-dialog class="overlayCont smallOverlay" scrollable v-model="tViews.filter">
        <div class="ovCard ovCardDayView small">
            <div class="ovCardTitleWrapper">
                <div class="">
                    <div class="ovTitle">
                        Aufgaben filtern</div>
                </div>
                <div class="closeBtnWrapper">
                    <v-btn @click="openView('')" color="var(--cMPM)" variant="text" icon="mdi-close-circle"></v-btn>
                </div>
            </div>
            <v-divider class="ovTitleDivider"></v-divider>
            <div class="px-6">
                <tasks-filter @close="openView('')"  :sDate="$store.state.tasksConfig.sDate" :eDate="$store.state.tasksConfig.eDate"></tasks-filter>
            </div>
        </div>
    </v-dialog>
</div>
</template>

<script>
import TasksFilter from '@/components/tasksFilter.vue'
import DailyView from '@/components/dailyView.vue'

export default {
    components: { DailyView, TasksFilter },
    data() {
        return {
            tViews: {
                dayview: false,
                filter: false,
                openPro: false
            }

        }
    },
    methods: {
        openView(t) {
            Object.keys(this.tViews).forEach(o => {
                this.tViews[o] = false;
            });
            if (t == 'dayview') {
                this.tViews.dayview = true;
            }
            if (t == 'filter') {
                this.tViews.filter = true;
            }
        },
    }
}
</script>
