const taskScheme = () => {
  let ntS = {
    uid: -1,
    Name: "",
    Project: {
      Name: "My Little Project",
      pid: -1,
    },
    DueDate: "",
    tStart: "",
    tEnd: "",
    aUser: "",
  };
  return ntS;
};
export default taskScheme;
