<template>
<div class="name py-24 lg:py-32">
    <div v-if="pr != null" class="container mx-auto">
        <v-card class="taskOvCard" flat>
            <v-row>
                <v-col class="pb-0" cols="12">
                    <div class="currProjectInd">
                        {{prWtLabel}}
                    </div>
                </v-col>
            </v-row>
            <v-row class="fyCenter">
                <v-col cols="12" md="5" class="">
                    <div class="bigTitle">
                        <h1 class="my-0 text-dark"> {{pr.attributes.Name}}</h1>
                    </div>
                </v-col>
                <v-col cols="12" md="7" class="fxEnd">
                    <v-btn-toggle class="mpmNavBtnToggle" v-model="tab" variant="flat" divided rounded="0" color="var(--cMPM)" group>
                        <v-btn value="one">
                            Informationen
                        </v-btn>

                        <v-btn value="two">
                            Accounts
                        </v-btn>

                        <v-btn @click="newOn = false" value="three">
                            Ansprechpartner
                        </v-btn>

                    </v-btn-toggle>

                </v-col>
            </v-row>
        </v-card>
        <v-card class="taskOvCard" flat>
            <v-row>
                <v-col cols="12" class="mt-6 min-h-screen pb-6">
                    <v-card class="bg-white" flat>

                        <v-card-text class="pb-4 px-0">
                            <v-window v-model="tab">
                                <v-window-item value="one">
                                    <div class="currProjectInd mb-2">
                                        Allgemeine Informationen
                                    </div>
                                    <div class="pt-2">
                                        <EditorMPM v-model="Infos" />
                                    </div>
                                </v-window-item>
                                <v-window-item value="two">
                                    <div class="currProjectInd mb-2">
                                        Accountinformationen
                                    </div>
                                    <div class="pt-2">
                                        <EditorMPM v-model="Accs" />
                                    </div>
                                </v-window-item>
                                <v-window-item value="three">
                                    <div class="currProjectInd mb-2">
                                        Ansprechpartner
                                    </div>
                                    <div v-if="aPs.length">
                                        <v-list class="bg-white">
                                            <v-list-item v-for="(ap, i) in aPs" :key="i" class="mb-4 opacity-100 apList bg-gray-100" active-color="primary" variant="plain">
                                                <template v-slot:append>
                                                    <v-btn v-show="panel[i] === true" variant="plain" @click="panel[i] = false" icon="mdi-minus"></v-btn>
                                                    <v-btn v-show="panel[i] === false" variant="plain" @click="panel[i] = true" icon="mdi-pencil"></v-btn>
                                                </template>
                                                <v-list-item-title>
                                                    <v-row class="py-3 fyCenter text-xs ml-2 md:ml-4">
                                                        <v-col cols="12" sm="4" class="fyCenter">
                                                            <div v-show="panel[i] === false" class="fyCenter">
                                                                <v-icon icon="mdi-account" class="mr-3"></v-icon>
                                                                <span>{{ap.Name}}</span>
                                                            </div>
                                                            <v-text-field v-show="panel[i] === true" v-model="ap.Name" hide-details label="Name"></v-text-field>
                                                        </v-col>
                                                        <v-divider class="-mx-4 sm:hidden my-2 md:-mx-6"></v-divider>

                                                        <v-col cols="12" sm="4" class="fyCenter">
                                                            <div v-show="panel[i] === false" class="fyCenter">
                                                                <v-icon icon="mdi-email" class="mr-3"></v-icon>
                                                                <span>{{ap.Email}}</span>
                                                            </div>
                                                            <v-text-field v-show="panel[i] === true" v-model="ap.Email" hide-details label="Email"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" sm="4">
                                                            <div v-show="panel[i] === false" class="fyCenter">
                                                                <v-icon icon="mdi-phone" class="mr-3"></v-icon>
                                                                <span>{{ap.Telefon}}</span>
                                                            </div>
                                                            <v-text-field v-show="panel[i] === true" v-model="ap.Telefon" hide-details label="Telefon"></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <div v-show="panel[i] === true" class="w-full py-4 mt-2 fxEnd">
                                                        <v-btn @click="deleteApp(i)" variant="plain" class="opacity-100 font-bold mr-6" color="rgb(220 38 38)" size="small">
                                                            <v-icon start icon="mdi-trash-can"></v-icon>
                                                            löschen
                                                        </v-btn>
                                                        <v-btn @click="updateApp({ 'Ansprechpartner': aPs })" variant="plain" class="opacity-100 font-bold" color="var(--cMPM)" size="small">
                                                            <v-icon start icon="mdi-content-save"></v-icon>
                                                            Speichern
                                                        </v-btn>
                                                    </div>
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </div>
                                    <div class="py-6" v-else>
                                        <v-list-item class="text-center apList py-8 bg-gray-100" active-color="primary" variant="plain">
                                            <em>Es sind noch keine Ansprechpartner eingetragen worden. </em>
                                        </v-list-item>
                                    </div>
                                    <div class="py-2 fxEnd">
                                        <v-btn :disabled="newOn" @click="addApOv = true">Hinzufügen</v-btn>
                                    </div>
                                </v-window-item>
                            </v-window>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </div>
    <v-dialog class="overlayCont smallOverlay centered" scrollable v-model="addApOv">
        <div class="ovCard small">
            <div class="ovCardTitleWrapper">
                <div class="">
                    <div class="ovTitle">
                        Ansprechpartner hinzufügen</div>
                </div>
                <div class="closeBtnWrapper">
                    <v-btn @click="addApOv = false" color="var(--cMPM)" variant="text" icon="mdi-close-circle"></v-btn>
                </div>
            </div>
            <div class="px-6">
                <div class="mb-4">
                    <v-text-field v-model="newAp.Name" hide-details label="Name"></v-text-field>
                </div>
                <div class="mb-4">
                    <v-text-field v-model="newAp.Email" hide-details label="Email"></v-text-field>
                </div>
                <div class="mb-4">
                    <v-text-field v-model="newAp.Telefon" hide-details label="Telefon"></v-text-field>
                </div>
                <div class="py-4 fxEnd">
                    <v-btn color="var(--cMPM)" @click="addAp" variant="tonal">Hinzufügen</v-btn>
                </div>
            </div>

        </div>
    </v-dialog>

    <v-dialog class="overlayCont centered" scrollable v-model="showWarn">
        <div class="ovCard xs-small">
            <div class="w-full text-center p-4">
                Alle Änderungen verwerfen?
            </div>
            <div class="fCenter w-full">
                <div>
                    <v-btn class="mb-4 block w-44" @click="withDrawCurrent">Ja</v-btn>
                    <v-btn variant="outlined" class="block w-44" @click="showWarn = false">zurück</v-btn>
                </div>
            </div>
        </div>
    </v-dialog>
</div>
</template>

<script>
import { getProjectByID } from '@/graphql/queries'
import { updateProject } from '@/graphql/mutations'
import workTypeLabel from '@/composables/getWorkTypeLabel';
import EditorMPM from '@/components/EditorMPM.vue';

export default {
    components: { EditorMPM },
    props: {
        id: {
            type: String,
        },
    },
    data() {
        return {
            pr: null,
            aPs: [],
            prWtLabel: null,
            Infos: '',
            Accs: '',
            tab: 'one',
            panel: [],
            newAp: {
                Name: '',
                Email: '',
                Telefon: ''
            },
            newOn: false,
            showWarn: false,
            addApOv: false

        }
    },
    mounted() {},
    apollo: {
        project: {
            query: getProjectByID,
            variables() {
                return {
                    "id": parseInt(this.id),
                }
            },
            context: {
                headers: {
                    "Authorization": 'Bearer ' + localStorage.getItem('jwt')
                },
            },
            result(res) {
                this.pr = { ...res.data.project.data };
                this.aPs = [...this.pr.attributes.Ansprechpartner];
                this.Infos = this.pr.attributes.Informationen;
                this.Accs = this.pr.attributes.Accounts;
                this.aPs.forEach((a, index) => {
                    this.aPs[index] = {
                        Name: a.Name,
                        Email: a.Email,
                        Telefon: a.Telefon
                    }
                    this.panel[index] = false;
                });
                this.prWtLabel = workTypeLabel(this.pr.attributes.WorkType);

            },
            error(graphQLErrors) {
                console.log('errors', graphQLErrors)
            }

        },
    },
    methods: {
        closeCurrentProject() {
            this.showWarn = true;
        },
        withDrawCurrent() {
            this.showWarn = false;
            this.$emit('close');
        },
        addAp() {
            this.aPs.push(this.newAp);
            let data = {
                "Ansprechpartner": this.aPs
            }
            this.updateApp(data);
        },
        safeFull() {
            let data = {
                "Informationen": this.Infos,
                "Accounts": this.Accs
            }
            this.updateApp(data);
        },
        deleteApp(i) {
            this.aPs.splice(i, 1);
            let data = {
                "Ansprechpartner": this.aPs
            }
            this.newOn = false;
            this.updateApp(data);
        },
        updateApp(data) {
            let vars = {
                "ID": parseInt(this.id),
                "data": data
            }
            this.$apollo.mutate({
                    mutation: updateProject,
                    context: {
                        headers: {
                            "Authorization": 'Bearer ' + this.$store.state.user.jwt
                        },
                    },
                    variables: vars
                })
                .then(() => {
                    this.newAp = {
                        Name: '',
                        Email: '',
                        Telefon: ''
                    };
                    this.addApOv = false;
                    this.$apollo.queries.project.refetch();
                });
        }
    },
}
</script>

<style>
.ProseMirror {
    height: 100%;
    padding: 1rem;
}

.ProseMirror-focused {
    outline: none;
    border: none;
}

@media (max-width: 768px) {
    .editApBtn {
        height: 36px;
        min-height: 36px;
    }

    .editDialogWrapper .v-btn__prepend {
        margin-right: 0;
    }

    .editApBtn .v-expansion-panel-title__icon {
        display: none;
    }

}
</style>
