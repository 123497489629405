<template>
<div class="name py-24">
    <div v-if="!loading" class="container mx-auto">
        <v-row>
            <v-col cols="12" v-for="w,i in wLists" class="mb-0" :key="w" sm="6" md="4" lg="4">
                <v-card @click="wListOv[i].catOv = true, cCatInd = i, step = 1" class="shadow-xl h-full wListCard">
                    <div class="wlHeaderWrapper fxBetween pl-6 fyCenter">
                        <div class="wlInfoHead">
                            <div class="wlInfoName font-bold text-lg">
                                {{w.attributes.Category}}
                            </div>
                        </div>
                        <div class="wlIconWrapper w-28">
                            <w-list-icons :icon="w.attributes.Category" />
                        </div>
                    </div>
                    <hr class="">
                    <div class="wlInfoWrapper">
                        <div class="itemPrevs py-2">
                            <div v-for="(it ) in sortPrio(w.attributes.wishitems)" :key="it" 
                        
                            class="mb-1">
                                <v-row>
                                    <v-col class="py-1 pl-0" cols="8">
                                        <div class="flex">
                                            <div class="w-6 -ml-1">
                                                <svg class="w-6 h-6" viewBox="0 0 24 24">
                                                    <path fill="var(--cMPM)" d="M12,10A2,2 0 0,0 10,12C10,13.11 10.9,14 12,14C13.11,14 14,13.11 14,12A2,2 0 0,0 12,10Z" />
                                                </svg> </div>
                                            <span>
                                                {{it.Name}}
                                            </span>
                                        </div>

                                    </v-col>
                                    <v-col class="fxEnd py-1" cols="4">
                                        <div class="w-18">
                                           <span class="pr-2">{{tickLabels[it.Prio] }} </span>  {{it.Prio}} 
                                        </div>
                                    </v-col>
                                </v-row>

                            </div>

                        </div>
                    </div>
                </v-card>
                <v-dialog class="overlayCont smallOverlay" scrollable v-model="wListOv[i].catOv">
                    <div class="ovCard small">
                        <div class="ovCardTitleWrapper">
                            <div class="fyCenter">
                                <div class="ovTitle">
                                    {{w.attributes.Category}}
                                </div>
                                <v-btn size="x-small" variant="tonal" color="var(--cMPM)" @click="newWish()" class="text-mpm ml-2" prepend-icon="mdi-plus-circle">Neu</v-btn>

                            </div>
                            <div class="closeBtnWrapper py-2">
                                <v-btn size="x-small" :disabled="step < 2" variant="tonal" color="var(--cMPM)" @click="hideWish()" class="text-mpm mr-2" icon="mdi-chevron-left"></v-btn>

                                <v-btn @click="wListOv[i].catOv = false" size="x-small" color="var(--cMPM)" variant="tonal" icon="mdi-close-circle"></v-btn>
                            </div>
                        </div>
                        <v-divider class="ovTitleDivider"></v-divider>
                        <div class="px-6">

                            <v-window v-model="step">
                                <v-window-item :value="1">
                                    <v-list class="bg-white">
                                        <v-list-item v-for="(it, ind ) in w.attributes.wishitems" :key="it" @click="seeWish(it,ind)" class="cursor-pointer bg-gray-200 rounded-lg shadow-lg mb-4">
                                            <v-row class="fyCenter">
                                                <v-col cols="10">
                                                    <div class="wItemLabelType">
                                                        {{it.Type}}
                                                    </div>
                                                    <div class="mb-2">
                                                        {{it.Name}}
                                                    </div>
                                                </v-col>
                                                <v-col class="fxEnd" cols="2">
                                                    <v-btn size="small" variant="text" class="text-mpm" icon="mdi-chevron-right"></v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-list-item>
                                    </v-list>
                                </v-window-item>
                                <v-window-item :value="2">
                                    <v-card class="rounded-lg">
                                        <div class="showWishWrapper p-4">
                                            <v-row>
                                                <v-col class="py-0 " cols="12">
                                                    <div class="">
                                                        <div class="wItemLabelType mb-2"> Priorität </div>
                                                        <v-slider v-model="cWish.Prio" step="1" :min="1" :max="5" tick-size="5" :ticks="tickLabels" show-ticks="always"></v-slider>
                                                    </div>

                                                </v-col>
                                                <v-col class="pb-0" cols="12">
                                                    <div class="wItemLabelType mb-2"> Name </div>
                                                    <div class="mb-2 wItemTitle">
                                                        <v-text-field ref="wItemName" label="Titel" v-model="cWish.Name"></v-text-field>

                                                    </div>
                                                </v-col>
                              
                                                <v-col class="pt-0" cols="12">
                                                    <div class="fyCenter fxBetween mb-2">
                                                        <div class="wItemLabelType">
                                                            Infos
                                                        </div>
                                                        <v-btn variant="outlined" size="x-small" @click="editDesc = !editDesc" class="changeMPM">
                                                            <span v-if="!editDesc">
                                                                ändern
                                                            </span>
                                                            <span v-else>
                                                                speichern
                                                            </span>
                                                        </v-btn>
                                                    </div>
                                                    <div>
                                                        <v-card v-if="!editDesc" variant="tonal" class="showWishTextArea bg-white" :class="{'noInfo' : !cWish.Desc}">
                                                            <div class="p-3" v-html="cWish.Desc"></div>
                                                        </v-card>
                                                        <EditorMPM v-else v-model="cWish.Desc" />

                                                    </div>
                                                </v-col>
                                                <v-col class="pt-0" cols="12">
                                                    <div class="fyCenter fxBetween mb-2">
                                                        <div class="wItemLabelType">
                                                            Links
                                                        </div>
                                                        <v-btn variant="outlined" size="x-small" @click="editLink = !editLink" class="changeMPM">
                                                            <span v-if="!editLink">
                                                                ändern
                                                            </span>
                                                            <span v-else>
                                                                speichern
                                                            </span>
                                                        </v-btn>
                                                    </div>
                                                    <div>
                                                        <v-card v-if="!editLink" variant="tonal" class="showWishTextArea bg-white" :class="{'noInfo' : !cWish.Links}">
                                                            <div class="p-3" v-html="cWish.Links"></div>
                                                        </v-card>
                                                        <EditorMPM v-else v-model="cWish.Links" />

                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </div>
                                        <v-divider></v-divider>
                                        <v-card-actions class="bg-slate-100">
                                            <div class="fyCenter w-full fxBetween">
                                                <v-btn @click="deleteWish" variant="text" class="h-20" color="error" icon="mdi-trash-can"></v-btn>
                                                <v-btn prepend-icon="mdi-content-save" color="var(--cMPM)" @click="safeWishItems()">
                                                    Speichern
                                                </v-btn>
                                            </div>

                                        </v-card-actions>
                                    </v-card>
                                </v-window-item>
                            </v-window>
                        </div>
                    </div>
                </v-dialog>
            </v-col>
        </v-row>
    </div>
    <div class="min-h-screen mw-sm mx-auto fyCenter" v-else>
        <v-progress-linear indeterminate color="#FFF"></v-progress-linear>
    </div>
</div>
</template>

<script>
import { getWishlists } from '@/graphql/queries'
import { safeWishlist } from '@/graphql/mutations'
import wListIcons from '../components/wListIcons.vue';
import EditorMPM from '../components/EditorMPM.vue';
import wishItemScheme from '@/composables/wishItemScheme';

export default {
    components: { wListIcons, EditorMPM },
    data() {
        return {
            loading: true,
            wLists: [],
            wListOv: [],
            cWish: {},
            step: 1,
            cWishInd: 0,
            cCatInd: 0,
            delIndex: false,
            editWish: false,
            editDesc: false,
            editLink: false,
            tickLabels: {
                1: '🥶',
                2: '🌧',
                3: '🌝',
                4: '🌤',
                5: '🔥'
            },
        }
    },
    apollo: {
        wishlists: {
            query: getWishlists,
            context: {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('jwt')
                },
            },
            result(res) {
                this.wLists = structuredClone(res.data.wishlists.data);
                this.wLists.forEach((w, i) => {
                    this.wListOv[i] = {
                        "catOv": false,
                        "taskOv": []
                    };
                    w.attributes.wishitems.forEach((it, ix) => {
                        this.wListOv[i].taskOv[ix] = 1;
                    });
                });
                this.loading = false;
            },
            error(graphQLErrors) {
                console.log('errors', graphQLErrors)
            }
        }
    },
    methods: {
        seeWish(w, ind) {
            this.cWish = w;
            console.log(w);
            this.cWishInd = ind;
            this.step = 2;
        },
        hideWish() {
            this.cWish = {};
            this.step = 1;
        },
        prioBg(p) {
            if (p < 4) {
                return 'blue';
            }
            if (p < 7) {
                return 'orange';
            } else {
                return 'pink';
            }
        },
        safeWishItems() {
            this.editDesc = false;
            this.editLink = false;
            if (this.cWish.id === false) {
                delete this.cWish.id
            }
            let nWishes = structuredClone(this.wLists[this.cCatInd].attributes.wishitems);
            if (this.delIndex === false) {
                let cWishClone = structuredClone(this.cWish);
                nWishes[this.cWishInd] = cWishClone;
            } else {
                nWishes.splice(this.delIndex, 1);
            }

            nWishes.forEach((w, i) => {
                let nWish = wishItemScheme();
                nWish.id = w.id;
                nWish.Name = w.Name;
                nWish.Cost = w.Cost;
                nWish.Links = w.Links;
                nWish.Desc = w.Desc;
                nWish.Type = w.Type;
                nWish.Prio = w.Prio;
                nWishes[i] = nWish;
            });
            console.log(nWishes);
            let data = {
                "wishitems": nWishes
            }
            let wId = this.wLists[this.cCatInd].id;

            let vars = {
                "id": parseInt(wId),
                "data": data
            }
            this.$apollo.mutate({
                    mutation: safeWishlist,
                    context: {
                        headers: {
                            "Authorization": 'Bearer ' + this.$store.state.user.jwt
                        },
                    },
                    variables: vars

                })
                .then((res) => {
                    this.hideWish()
                    this.delIndex = false;
                    console.log(res);
                });
        },
        newWish() {
            let nWish = wishItemScheme();
            this.cWishInd = this.wLists[this.cCatInd].attributes.wishitems.length;
            this.cWish = nWish;
            this.step = 2;
        },
        deleteWish() {

            this.delIndex = this.cWishInd;
            this.safeWishItems();
            console.log(this.delIndex);
        },
        sortPrio(ws) {
            var res = ws.sort(({ Prio: a }, { Prio: b }) => b - a);
            return res;
        },
        log() { console.log(this.wLists) }

    }
}
</script>
