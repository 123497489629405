// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import {  md } from 'vuetify/iconsets/md'
import {aliases, mdi } from 'vuetify/iconsets/mdi'
import 'vuetify/styles'

// Vuetify
import {
  createVuetify
} from 'vuetify'

const mpmTheme = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#F4F4F4',
    primary: '#F4F4F4',
    'primary-darken-1': '#FFF',
    secondary: '#FFF',
    'secondary-darken-1': '#F4F4F4',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  }
}


export default createVuetify({
 theme: {
    defaultTheme: 'mpmTheme',
    themes: {
      mpmTheme,
    },
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
      md
    },
  },
})