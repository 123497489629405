<template>
<v-dialog class="overlayCont smallOverlay" scrollable v-model="openOv">
    <div class="ovCard ovCardDayView small">
        <div class="ovCardTitleWrapper">
            <div class="">
                <div class="ovTitle">
                    Projekte</div>
            </div>
            <div class="closeBtnWrapper">
                <v-btn @click="closeOv" color="var(--cMPM)" variant="text" icon="mdi-close-circle"></v-btn>
            </div>
        </div>
        <v-divider class="ovTitleDivider mb-2"></v-divider>
        <v-card-text class="bg-white mx-auto rounded-lg">
            <div class="searchWrapper relative mb-4">
                <v-text-field :append-inner-icon="!searchProjects.length ? 'md:add_box' : 'md:search'" hide-details class="bgDark text-white rounded-lg" v-model="pSearch" ref="searchpro" label="Projekt suchen">
                </v-text-field>
                <div class="mt-2">
                    <v-btn v-if="!searchProjects.length" variant="tonal" size="small" color="var(--cMPM)" prepend-icon="md:add_box">hinzufügen</v-btn>
                </div>
            </div>
            <v-table theme="dark" class="bgDark">
                <thead>
                    <tr>
                        <th class="text-left">
                            Name
                        </th>
                        <th class="text-left">
                            Typ
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="cursor-pointer" @click="goToP(item.id)" v-for="item in searchProjects" :key="item.id">
                        <td>{{ item.Name }}</td>
                        <td>{{ getwLabel(item.Type) }}</td>
                    </tr>
                    <tr v-if="!searchProjects.length" class="py-6">
                        <td>
                            Keine Ergebnisse für <b>{{pSearch}}</b> ...
                        </td>
                    </tr>
                </tbody>
            </v-table>
            <!-- <v-list class="bg-white px-2 mt-8">
                    <v-list-item v-for="(item, i) in searchProjects" :key="i" :value="item" class="bg-gray-200 mb-3 font-semibold rounded-md">
                        <v-row class="fyCenter px-2">
                            <v-col cols="12" md="6">
                                <v-list-item-title class="font-semibold" v-text="item.Name"></v-list-item-title>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-list-item-title class="font-semibold text-xs" v-text="getwLabel(item.Type)"></v-list-item-title>
                            </v-col>
                            <v-col cols="12" class="fxEnd" md="3">
                                <v-list-item class="py-4" append-icon="md:arrow_forward_ios" title="Details" value=""></v-list-item>
                            </v-col>
                        </v-row>
                    </v-list-item>
                </v-list>
                <div v-if="!searchProjects.length" class="text-center py-6">
                    Keine nicht-aktiven Projekte gefunden.
                </div> -->
        </v-card-text>
    </div>
</v-dialog>
</template>

<script>
import { getProjects } from '@/graphql/queries'
import getWorkTypeLabel from '@/composables/getWorkTypeLabel'
export default {
    props: {
        open: {
            type: Boolean
        }
    },
    data() {
        return {
            pSearch: '',
            ovProjects: [],
        }
    },
    computed: {
        openOv() {
            return this.open;
        },  
        searchProjects() {
            let tempProjects = this.ovProjects;
            if (this.pSearch != '' && this.pSearch) {
                tempProjects = tempProjects.filter((item) => {
                    let Name = item.Name;
                    return Name
                        .toUpperCase()
                        .includes(this.pSearch.toUpperCase())
                })
            }
            return tempProjects;
        },
    },
    apollo: {
        projects: {
            query: getProjects,
            variables() {
                return {

                }
            },
            context: {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('jwt')
                },
            },
            result(res) {
                res.data.projects.data.forEach(p => {
                    let item = {
                        id: p.id,
                        Name: p.attributes.Name,
                        Type: p.attributes.WorkType
                    };
                    this.ovProjects.push(item)

                });
            },
            error(graphQLErrors) {
                console.log('errors', graphQLErrors)
            }
        }
    },
    methods: {
        getwLabel(t) {
            return getWorkTypeLabel(t)
        },
        addSearchIcon() {
            if (this.searchProjects.length) {
                return 'md:search'
            } else {
                return 'md:add_box'
            }
        },
        closeOv() {
            this.openOv = false;
            this.$emit('close');
        },
        goToP(id) {
            this.openOv = false;
            this.$emit('close');
            this.$router.push({ path: '/project/' + id })
        }
    }
}
</script>
