<template>
  <div class="name relative py-24 lg:py-32">
    <div class="container mx-auto">
      <div v-if="!loading">
        <v-row>
          <v-col cols="12">
            <h1 class="text-white">Dokumente und Accounts</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <pw-mgmt :uid="user.id" />
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import pwMgmt from "../components/pwMgmt.vue";

export default {
  components: { pwMgmt },
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
  },
};
</script>
