import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/HomeView.vue";
import DashboardView from "../views/DashboardView.vue";
import WishlistViewVue from "@/views/WishlistView.vue";
import BirthdayViewVue from "@/views/BirthdayView.vue";
import ProjectViewVue from "@/views/ProjectView.vue";
import FinanceView from "@/views/FinancesVue.vue";
import DocumentView from "@/views/documentView.vue";

function requireAuth(to, from, next) {
  var isAuthenticated = false;
  if (localStorage.getItem("user")) isAuthenticated = true;
  else isAuthenticated = false;
  if (isAuthenticated) {
    next(); // allow to enter route
  } else {
    next("/"); // go to '/login';
  }
}

function isLogged(to, from, next) {
  var isAuthenticated = false;
  if (localStorage.getItem("user")) isAuthenticated = true;
  else isAuthenticated = false;
  if (isAuthenticated) {
    next("/dashboard"); // allow to enter route
  } else {
    next(); // go to '/login';
  }
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: isLogged,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardView,
    beforeEnter: requireAuth,
  },
  {
    path: "/whishlists",
    name: "Wishlists",
    component: WishlistViewVue,
    beforeEnter: requireAuth,
  },
  {
    path: "/birthdays",
    name: "Geburtstage",
    component: BirthdayViewVue,
    beforeEnter: requireAuth,
  },
  {
    path: "/finances",
    name: "Finanzen",
    component: FinanceView,
    beforeEnter: requireAuth,
  },
  {
    path: "/project/:id",
    name: "Projekt",
    props: true,
    component: ProjectViewVue,
    beforeEnter: requireAuth,
  },
  {
    path: "/documents",
    name: "Dokumente",
    props: true,
    component: DocumentView,
    beforeEnter: requireAuth,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
