
import taskWorkTypes from '@/composables/workTypeScheme';

const workTypeLabel = (val) => {
    let tTypes = taskWorkTypes;
    let tLabel = '';

    tTypes.forEach(wtype => {
        if(wtype.value == val) {
            tLabel = wtype.Name;
        }
    });

    return tLabel;
}

export default workTypeLabel;
