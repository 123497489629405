const taskWorkTypes = [
    {
        "Name" : "Arbeit",
        "value" : "work"
    },
    {
        "Name" : "Privat",
        "value" : "private"
    }
]

export default taskWorkTypes;
