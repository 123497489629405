<template>
<div class="name bg-gradient-to-r from-violet-700 min-h-screen relative to-bg-pgalt py-24 lg:py-32">
    <div class="container mx-auto">
        <div v-if="!loading">
            <v-row>
                <v-col cols="12">
                    <h1 class="text-white">Geburtstage</h1>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card class="taskOvCard h-full fyCenter">
                        <DatePicker @dayclick="dayClicked" class="border-white" color="gray" :attributes="attributes" v-model="showDate" mode="date" locale="de" is-expanded />
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card class="taskOvCard h-full">
                        <div class="fyBetween h-full">
                            <h3 class="text-mpm"> {{$moment(showDate).format('dddd')}}, {{$moment(showDate).format('DD.MM.YYYY')}}</h3>
                            <div class="bdayListContent h-full">
                                <div class="py-3" v-if="datePers.length">
                                    <div v-for="(pers, index) in datePers" v-bind:key="pers.id" class="">
                                        <v-row class="fyCenter">
                                            <v-col cols="10">

                                                <div class="font-semibold text-mpm">
                                                    {{pers.name}}
                                                </div>
                                            </v-col>
                                            <v-col class="fxEnd py-0" cols="2">
                                                <v-btn size="small" variant="text" @click="updateDayEntry('del',index)" class="text-red" icon="mdi-trash-can"></v-btn>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </div>
                                <div class="noB py-3" v-else>
                                    <em>Keine Geburtstage gefunden.</em>
                                </div>
                            </div>
                            <div class="bdayListFooter">
                                <div class="botDivider mt-4 mb-3 taskOvInvPx"></div>
                                <div class="fyCenter">
                                    <div class="w-60 mr-3">
                                        <v-text-field v-on:keyup.enter="addBday(showDate)" placeholder="Geburtstag eintragen" v-model="nameToAdd" density="compact" hide-details></v-text-field>
                                    </div>
                                    <v-btn @click="addBday(showDate)" color="var(--cMPM)" variant="text" icon="mdi-plus-circle-outline"></v-btn>
                                  
                                </div>
                            </div>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <div class="w-full loaderFullScreen mw-sm mx-auto fyCenter" v-else>
            <v-progress-linear indeterminate color="#FFF"></v-progress-linear>
        </div>
    </div>
</div>
</template>

<script>
import { getBirthdays } from '@/graphql/queries'
import { createBirthday } from '@/graphql/create'
import { updateBirthday } from '@/graphql/mutations'
import formatDate from '@/composables/timeFormat';

export default {
    data() {
        return {
            showDate: new Date(),
            attributes: [],
            birthdays: [],
            currID: 0,
            datePers: [],
            nameToAdd: '',
            loading: true,
        }
    },
    apollo: {
        birthdays: {
            query: getBirthdays,
            context: {
                headers: {
                    "Authorization": 'Bearer ' + localStorage.getItem('jwt')
                },
            },
            result() {
                const currYear = this.$moment().format('YYYY');
                for (var i = 0, len = this.birthdays.data.length; i < len; i++) {
                    let date = this.birthdays.data[i];
                    let dEntry = new Date(this.$moment(currYear + '-' + date.attributes.Date));
                    if (this.$moment(dEntry).isSame(this.showDate, 'day')) {
                        this.datePers = date.attributes.Birthdays;
                        this.currID = date.id;
                    }
                    let dotC = '';
                    if (date.attributes.Birthdays.length) {
                        dotC = 'purple';
                    }
                    let bday = {
                        dot: dotC,
                        dates: dEntry,
                        customData: {
                            'id': date.id,
                            'pers': date.attributes.Birthdays
                        }
                    }
                    this.attributes.push(bday);
                }

                this.loading = false;
            }
        }
    },
    methods: {
        dayClicked(day) {
            this.showDate = day.date;
            if (day.attributes.length) {
                this.datePers = day.attributes[0].customData.pers;
                this.currID = day.attributes[0].customData.id;
            } else {
                this.datePers = [];
                this.currID = false;
            }
        },
        addBday(showDate) {
            if (this.currID !== false) {
                this.updateDayEntry('add', 0);
            } else {
                this.createDayEntry(showDate);
            }
        },
        createDayEntry(date) {
            let nDate = this.$moment(date).format('MM-DD');
            let vars = {
                data: {
                    Date: nDate,
                    publishedAt: formatDate('', 'iso'),
                    Birthdays: [{
                        name: this.nameToAdd
                    }]
                }
            };
            this.$apollo.mutate({
                mutation: createBirthday,
                context: {
                    headers: {
                        "Authorization": 'Bearer ' + this.$store.state.user.jwt
                    },
                },
                variables: vars
            }).then((res) => {
                this.currID = res.data.createBirthday.data.id;
                this.restBdays();

            });
        },
        updateDayEntry(type, index) {
            let nBday = [...this.datePers];
            if (type == 'add') {
                let nName = {
                    name: this.nameToAdd
                }
                nBday.push(nName);
            } else if (type == 'del') {
                nBday.splice(index, 1)
            }
            this.$apollo.mutate({
                mutation: updateBirthday,
                variables: {
                    id: this.currID,
                    data: {
                        Birthdays: nBday
                    }
                }
            }).then(() => {
                this.restBdays();
            });
        },
        restBdays() {
            this.nameToAdd = '';
            this.attributes = [];
            // this.birthdays = [];
            // this.datePers = [];
            this.$apollo.queries.birthdays.refetch();
            // let attrIndex = this.attributes.findIndex(x => x.customData.id == this.currID);
            // console.log(this.attributes);
            // let day = this.attributes[attrIndex];
            // console.log(day);
            // this.dayClicked(day);
        }
    }
}
</script>
