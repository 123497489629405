<template>
<div class="w-full min-h-screen bg-white fyCenter mx-auto">
    <div class="absolute hidden sm:block h-100 bgPrime-300 shadow-lg z-0 left-0 top-0 w-1/2">
    </div>
    <v-container>
        <v-row>
            <v-col cols="12" sm="6" class="fCenter md:min-h-screen relative z-6  py-6">
                <mpm-logo class="loginLogo" />
            </v-col>
            <v-col cols="12" sm="6" class="fCenter py-6">
                <div class="loginWrapper w-full mw-xs mx-auto">
                    <h3 class="mb-4">Login</h3>
                    <v-form action="#" @submit.prevent="login" v-model="valid">
                        <v-text-field v-model="identifier" :rules="nameRules" label="Username/E-Mail" required></v-text-field>
                        <v-text-field type="password" v-model="password" :rules="passWordRules" label="Password" required></v-text-field>
                        <v-btn type="submit" block size="x-large">
                            Login
                        </v-btn>
                    </v-form>
                </div>
            </v-col>
        </v-row>
    </v-container>

</div>
</template>

<script>
import MpmLogo from '@/components/mpmLogo.vue';
export default {
    components: { MpmLogo },
    data: () => ({
        valid: false,
        identifier: '',
        password: '',
        nameRules: [
            v => !!v || 'Name is required',
            // v => v.length <= 10 || 'Name must be less than 10 characters',
        ],
        email: '',
        passWordRules: [
            v => !!v || 'Password is required',
            // v => /.+@.+/.test(v) || 'E-mail must be valid',
        ],
    }),
    methods: {
        login() {
            this.$store
                .dispatch('login', {
                    identifier: this.identifier,
                    password: this.password
                })
                .then((data) => {
                    localStorage.setItem('jwt', data.jwt);
                    this.$router.push({
                        name: 'Dashboard'
                    })
                })
                .catch((err) => {
                    console.log(err);
                    this.error = true;
                    this.identifier = '';
                })
        },
    }
}
</script>
