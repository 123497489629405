<template>
  <div v-if="!loading" class="name">
    <!-- <pre>
        {{ projects }}
    </pre> -->
    <div class="taskOvCard pb-1">
      <v-tabs v-model="mgTab" bg-color="white">
        <v-tab value="quick">Schnell</v-tab>
        <v-tab value="project">Projekte</v-tab>
      </v-tabs>

      <v-window class="py-4" v-model="mgTab">
        <v-window-item value="quick">
          <v-text-field v-model="mgSearch" clearable hide-details="auto" label="Schnellsuche"></v-text-field>
        </v-window-item>
        <v-window-item value="project">
          <v-autocomplete
            v-model="cProject"
            clearable
            :items="projects"
            item-title="attributes.Name"
            color="blue-grey-lighten-3"
            class="bg-white"
            item-value="id"
            hide-details
            label="Projektsuche"
          />
        </v-window-item>
      </v-window>

      <div class="results mt-4">
        <div class="pb-2 font-semibold">Ergebnisse</div>
        <div v-if="filteredResults.length > 0">
          <div v-for="item in filteredResults" :key="item.Name">
            <div class="resItem taskOvCard pt-2 border-grey-100 border pb-2">
              <div class="resItemHeader">
                <span class="font-semibold">{{ item.Name }}</span> | {{ item.Projectname }}
              </div>
              <div class="">
                <div>{{ item.Link }}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- Display Message if No Results -->
        <p class="mb-4" v-else-if="mgSearch.length > 1">No results found.</p>
        <p class="mb-4" v-else>Bitte nutze die Suchfunktion</p>
      </div>
    </div>
    <v-dialog class="overlayNavModal" v-model="addMgmt">
      <v-card>
        <v-row no-gutters>
          <v-col cols="12" md="6"> </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getUserPwMgmt } from "@/graphql/queries";
export default {
  props: {
    uid: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      loading: true,
      addMgmt: false,
      projects: [],
      cProject: [],
      mgSearch: "",
      mgTab: "quick",
    };
  },
  apollo: {
    usersPermissionsUser: {
      query: getUserPwMgmt,
      variables() {
        return {
          id: parseInt(this.uid),
        };
      },
      context: {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt"),
        },
      },
      result(res) {
        this.projects = [...res.data.usersPermissionsUser.data.attributes.projects.data];
        this.loading = false;
        // console.log(this.projects);
      },
      error(graphQLErrors) {
        console.log("errors", graphQLErrors);
      },
    },
  },
  computed: {
    filteredResults() {
      if (this.mgSearch.length <= 1) {
        return [];
      }

      let results = [];

      this.projects.forEach((item) => {
        if (item.attributes && item.attributes.accMgmt) {
          item.attributes.accMgmt.forEach((subItem) => {
            if (
              item.attributes.Name.toLowerCase().includes(this.mgSearch.toLowerCase()) ||
              subItem.Name.toLowerCase().includes(this.mgSearch.toLowerCase()) ||
              subItem.Link.toLowerCase().includes(this.mgSearch.toLowerCase()) ||
              subItem.User.toLowerCase().includes(this.mgSearch.toLowerCase())
            ) {
              let nres = {
                Projectname: item.attributes.Name,
                Name: subItem.Name,
                Link: subItem.Link,
                User: subItem.User,
                Pass: subItem.Password,
              };
              results.push(nres);
            }
          });
        }
      });

      return results;
    },
  },
  methods: {
    accEntryLengths(p) {
      return p.attributes.accMgmt.length;
    },
  },
};
</script>
