<template>
<div class="name bg-gradient-to-r from-bg-pmatch  min-h-screen relative to-bg-pgalt py-24 lg:py-32">
    <div v-if="!loading" class="container mx-auto">
        <div>
            <v-row>
                <v-col cols="12">
                    <div class="fyCenter fxBetween text-white">
                        <div class="hidden md:inline-block">
                            <h1 class="mb-1">Finanzen</h1>
                        </div>
                        <div class="financeControl fyCenter">
                            <div class="h-8 rounded bg-white mr-2 md:mr-4 fCenter">
                                <v-btn @click="openModal('filterExpenses')" size="small" variant="text" class="text-mpm" prepend-icon="mdi-knob"><span class="">Filter</span></v-btn>
                            </div>
                            <div class="h-8 rounded bg-white fCenter">
                                <v-btn @click="openModal('newExpenseModal')" size="small" variant="text" class="text-mpm" prepend-icon="mdi-plus-circle-outline"><span class="">hinzufügen</span></v-btn>
                            </div>
                        </div>
                    </div>
                    <div class="text-white mt-3 md:mt-0">
                        {{fDate(sDate) }} - {{fDate(eDate)}}
                    </div>
                </v-col>
            </v-row>
            <v-row class="mt-4 mx-auto w-full">
                <v-col cols="12" sm="12" md="4" lg="4">
                    <v-card class="p-2 w-full">
                        <template v-slot:title>
                            <div class="text-base md:text-lg">
                                <b> Übersicht </b>
                            </div>
                        </template>

                        <v-card-text>
                            <div>
                                <div v-for="c in fcatTotal()" :key="c" class="fyCenter text-sm md:text-base fxBetween">

                                    <div>{{(c.Name)}} ({{c.length}})</div>
                                    <div :class="c.class"><b>{{c.Total}}</b></div>
                                </div>
                                <hr class="my-1">
                                <div class="cTotal fyCenter fxBetween">
                                    <b>
                                        Total ({{allTotal().length}})
                                    </b>
                                    <b>
                                        {{allTotal().val}}
                                    </b>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="12" md="8" lg="8">
                    <v-card class="w-full">
                        <v-card-text class="py-0">
                            <v-window>
                                <v-window-item>
                                    <v-row v-for="f in financeTasks" :key="f" :value="f.id" class="mt-1 pb-5 relative  fTaskOv fyCenter md:pb-0 mb-1 md:border-b">
                                        <v-col class="pb-0 pt-2 fyCenter w-full md:pb-2" cols="12" md="4">
                                            <span class="md:hidden"> <b :class="[{'text-red' : f.attributes.isExp},{'text-green' : !f.attributes.isExp}]">{{intToCurr(f.attributes.Value)}} </b><span class="px-2">|</span></span> <b class="text-sm tbprTaskTitleWrap"> {{f.attributes.Name }} </b>
                                        </v-col>
                                        <v-col class="pb-0 text-sm pt-1 md:pb-2 fTaskSubInfo" cols="12" md="4">
                                            <b>{{f.attributes.finance_cat.data.attributes.Name}}</b><span class="px-2">|</span>{{$moment(f.attributes.Date).format('DD/MM/YYYY') }}
                                        </v-col>
                                        <v-col class="pb-0 text-sm pt-1 md:pb-2" cols="12" md="2">
                                            <span class="md:inline-block hidden"> <b :class="[{'text-red' : f.attributes.isExp},{'text-green' : !f.attributes.isExp}]">{{intToCurr(f.attributes.Value)}} </b> </span>
                                        </v-col>
                                        <v-col class="pb-0 md:pb-2 editfTaskCol flex justify-end" cols="4" md="2">
                                            <v-btn @click="editExpense(f)" size="small" variant="text" class="text-gray-400" icon="mdi-pencil-circle"></v-btn>
                                        </v-col>
                                    </v-row>
                                </v-window-item>
                            </v-window>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
    <div class="overlayCont customOv smallOverlay" v-show="newExpenseModal">
        <div v-if="newExpenseModal" class="ovCard small">
            <div class="ovCardTitleWrapper">
                <div class="fyCenter">
                </div>
                <div class="closeBtnWrapper">
                    <v-btn @click="newExpenseModal = false, $store.dispatch('setOvLocked', false),
 cleanExp()" color="var(--cMPM)" variant="text" icon="mdi-close-circle"></v-btn>
                </div>
            </div>
            <div class="px-6">
                <v-row>
                    <v-col cols="12">
                        <div class="tNameInputWrapper">
                            <v-text-field density="comfortable" ref="tName" v-on:keyup.enter="updateExpense" v-model="newExpense.Name" hide-details label="Task"></v-text-field>
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <DatePicker color="gray" v-model="newExpense.Date" mode="date" locale="de" is-expanded />
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6">
                        <v-select density="comfortable" v-model="newExpense.finance_cat.data" label="Select" item-title="attributes.Name" item-value="id" :items="fcats"></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6">
                        <div class="relative">
                            <v-text-field density="comfortable" ref="tName" v-on:keyup.enter="updateExpense" v-model="newExpense.Value" hide-details label="Wert"></v-text-field>
                            <v-btn @click="newExpense.isExp = !newExpense.isExp" class="right-0 rounded-br-none top-0 bottom-0 fCenter h-full expPlusMinus absolute" :class="[{'bg-red' : newExpense.isExp},{'bg-green' : !newExpense.isExp}]" variant="tonal">
                                <span v-if="newExpense.isExp" class="expSym minusExp">-</span>
                                <span v-if="!newExpense.isExp" class="expSym plusExp">+</span>
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
                <v-row class="fyCenter">
                    <v-col cols="4" class="mb-2">
                        <v-btn v-if="newExpense.id != -1" @click="deleteExp" variant="text" class="h-20" color="error" icon="mdi-trash-can"></v-btn>
                    </v-col>
                    <v-col cols="8" class="mb-2 fxEnd">
                        <v-btn v-if="newExpense.id != -1" @click="updateExpense">
                            <v-icon start icon="mdi-plus-circle-outline"></v-icon>
                            <span> speichern</span>
                        </v-btn>
                        <v-btn v-if="newExpense.id == -1" @click="updateExpense">
                            <v-icon start icon="mdi-plus-circle-outline"></v-icon>
                            <span> Erstellen</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
    <div class="overlayCont customOv smallOverlay" v-show="filterExpenseModal">
        <div v-if="filterExpenseModal" class="ovCard small">
            <div class="ovCardTitleWrapper">
                <div class="fyCenter">
                </div>
                <div class="closeBtnWrapper">
                    <v-btn @click="filterExpense" color="var(--cMPM)" variant="text" icon="mdi-close-circle"></v-btn>
                </div>
            </div>
            <div class="px-6">
                <v-row>
                    <v-col cols="12">
                        <v-select chips multiple density="comfortable" v-model="filterCats" label="Select" item-title="attributes.Name" item-value="id" :items="fcats"></v-select>
                    </v-col>
                    <v-col cols="12">
                        <DatePicker color="gray" v-model="filterRange" is-range mode="date" locale="de" is-expanded />
                    </v-col>

                </v-row>
                <v-row>
                    <v-col class="fxEnd">
                        <v-btn @click="filterExpense">
                            <v-icon start icon="mdi-knob"></v-icon>
                            <span> Filter</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import moment from "moment"
import { getFinancesByDates, getFCats } from '@/graphql/queries'
import { updateFinance, deleteFinance } from '@/graphql/mutations'
import formatDate from '@/composables/timeFormat';
import { createFiTask } from '@/graphql/create'

export default {

    data() {
        return {
            financeTasks: [],
            wTasks: [],
            loading: true,
            newExpenseModal: false,
            filterExpenseModal: false,
            fcats: [],
            filterCats: [],
            filterRange: {
                start: "",
                end: ""
            },
            docRange: {
                start: "",
                end: ""
            },
            newExpense: {
                "id": -1,
                "Name": "",
                "Value": 0,
                "isExp": true,
                "Date": new Date(),
            },
            startDate: moment().startOf('month').format('YYYY-MM-DD'),
            endDate: moment().endOf('month').format('YYYY-MM-DD')

        }
    },
    created() {
        this.filterRange.start = this.sDate;
        this.filterRange.end = this.eDate;
        this.docRange.start = this.sDate;
        this.docRange.end = this.eDate;
    },
    computed: {
        cId() {
            var cDate = moment();
            var month = cDate.format('M');
            var year = cDate.format('YY');
            if (month.length < 2) {
                month = '0' + month;
            }
            return month + year;
        },
        mLabel() {
            var cDate = moment();
            var month = cDate.format('MMM');
            var year = cDate.format('YYYY');
            return month + ' ' + year;
        },
        sDate() {
            if (this.filterRange.start == "") {
                return moment().startOf('month').format('YYYY-MM-DD');
            } else {
                return moment(this.filterRange.start).startOf('month').format('YYYY-MM-DD');
            }
        },
        eDate() {
            return moment().endOf('month').format('YYYY-MM-DD');
        }
    },
    apollo: {
        finances: {
            query: getFinancesByDates,
            variables() {
                return {
                    "sDate": this.startDate,
                    "eDate": this.endDate,
                }
            },
            context: {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('jwt')
                },
            },
            result(res) {
                console.log(this.sDate);
                let fTaskTemp = structuredClone(res.data.finances.data);
                let orderTask = fTaskTemp.sort(function (a, b) {
                    let aDate = new Date(moment(a.attributes.Date));
                    let bDate = new Date(moment(b.attributes.Date));
                    return bDate - aDate;
                });
                this.financeTasks = orderTask;
                this.wTasks = orderTask;
                this.loading = false;
            },
            error(graphQLErrors) {
                console.log('errors', graphQLErrors)
            }
        },
        financeCats: {
            query: getFCats,
            context: {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('jwt')
                },
            },
            result(res) {
                this.fcats = structuredClone(res.data.financeCats.data);
            }
        }

    },
    methods: {
        openModal(val) {
            if (val == 'newExpenseModal') {
                this.cleanExp();
                this.newExpenseModal = true;
            }
            if (val == 'filterExpenses') {
                this.filterExpenseModal = true;
            }
            this.$store.dispatch('setOvLocked', true);

        },
        intToCurr(val) {
            return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(val)
        },
        editExpense(exp) {
            let f = structuredClone(exp.attributes);
            this.newExpense = f;
            this.newExpense.id = exp.id;
            this.newExpenseModal = true;
            this.$store.dispatch('setOvLocked', true);
        },
        updateExpense() {
            if (this.newExpense.id == -1) {
                let nTask = {
                    "Name": this.newExpense.Name,
                    "Value": parseInt(this.newExpense.Value),
                    "publishedAt": formatDate('', 'iso'),
                    "finance_cat": this.newExpense.finance_cat.data,
                    "Date": moment(this.newExpense.Date).format('YYYY-MM-DD'),
                    "isExp": this.newExpense.isExp
                }
                this.$apollo.mutate({
                        mutation: createFiTask,
                        context: {
                            headers: {
                                "Authorization": 'Bearer ' + this.$store.state.user.jwt
                            },
                        },
                        variables: {
                            "data": nTask
                        },

                    })
                    .then(() => {
                        this.$apollo.queries.finances.refetch();
                        this.cleanExp();
                        this.newExpenseModal = false;
                    });
            } else {
                let cat = "";
                if (this.newExpense.finance_cat.data.id != null) {
                    cat = this.newExpense.finance_cat.data.id;
                } else {
                    cat = this.newExpense.finance_cat.data;
                }
                let nTask = {
                    "Name": this.newExpense.Name,
                    "Value": parseInt(this.newExpense.Value),
                    "finance_cat": cat,
                    "Date": moment(this.newExpense.Date).format('YYYY-MM-DD'),
                    "isExp": this.newExpense.isExp
                }

                this.$apollo.mutate({
                        mutation: updateFinance,
                        context: {
                            headers: {
                                "Authorization": 'Bearer ' + this.$store.state.user.jwt
                            },
                        },
                        variables: {
                            "id": this.newExpense.id,
                            "data": nTask
                        },

                    })
                    .then(() => {
                        this.$apollo.queries.finances.refetch();
                        this.cleanExp();
                        this.newExpenseModal = false;
                    });
            }
        },
        deleteExp() {
            this.$apollo.mutate({
                    mutation: deleteFinance,
                    context: {
                        headers: {
                            "Authorization": 'Bearer ' + this.$store.state.user.jwt
                        },
                    },
                    variables: {
                        "ID": this.newExpense.id
                    },
                })
                .then(() => {
                    this.$apollo.queries.finances.refetch();
                    this.cleanExp();
                    this.newExpenseModal = false;
                });
            this.cleanExp();
            this.newExpenseModal = false;

        },
        cleanExp() {
            this.newExpense = {
                "Name": "",
                "Value": 0,
                "Date": new Date(),
                "finance_cat": {},
                "id": -1,
                "isExp": true
            }
        },
        fDate(date) {
            return moment(date).format('DD.MM.YYYY');
        },
        allTotal() {
            let total = 0,
            length = 0;
            this.fcats.forEach(c => {
                let val = this.cTotal(c.id);
                length += val.length;
                total += val.val;
            });
            return { 
                "val": new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(total),
                "length": length
            }
        },
        cTotal(cid) {
            let val = 0;
            let length = 0;
            this.financeTasks.forEach(t => {
                if (t.attributes.finance_cat.data.id == cid) {
                    length++;
                    let cval = t.attributes.Value;
                    if (t.attributes.isExp) {
                        val -= cval;
                    } else {
                        val += cval;
                    }
                }
            });
            return {
                "val": val,
                "length": length
            };
        },
        filterExpense() {
            if(this.filterRange.start != "" && this.filterRange.end) {
                this.startDate = moment(this.filterRange.start).format('YYYY-MM-DD');
                this.endDate = moment(this.filterRange.end).format('YYYY-MM-DD');
            }
            let filterArrId = Object.values(this.filterCats);
            let fTasks = [];
            this.financeTasks = structuredClone(this.wTasks);
            if (filterArrId.length) {
                this.financeTasks.forEach(f => {
                    if (filterArrId.includes(f.attributes.finance_cat.data.id)) {
                        fTasks.push(f);
                    }
                });
                this.financeTasks = fTasks;
            }
            this.filterExpenseModal = false;
            this.$store.dispatch('setOvLocked', false);

        },
        fcatTotal() {
            let fcatz = [];
            this.fcats.forEach(c => {
                let cVal = this.cTotal(c.id);
                let cCat = {
                    "Name": c.attributes.Name,
                    "Total": new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(cVal.val),
                    "class": "",
                    "length": cVal.length
                };
                if (cVal.val < 0) {
                    cCat.class = "text-red";
                }
                if (cVal.val > 0) {
                    cCat.class = "text-green";
                }
                fcatz.push(cCat);
            });
            return fcatz;
        }
    }
}
</script>

<style>
.fTaskOv .tbprTaskTitleWrap {
    max-width: 160px;
}
.customOv {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2000;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .5);
    overflow-y: scroll;
    padding-top: 140px;
    padding-bottom: 40px;
}

@media (max-width: 992px) {
    .fTaskOv .editfTaskCol {
        position: absolute;
        right: .5rem;
        top: 0;
        z-index: 12;
        padding: 0;
    }

    .fTaskOv:not(:last-child):after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        width: 100%;
        background: #CCCCCC;
    }

    .financeControl span {
        font-size: 10px;
    }
}
@media (max-width: 768px) {
    .fTaskSubInfo {
        font-size: 12px !important;
    }
    .tbprTaskTitleWrap {
        position: relative;
    }
    .fTaskOv .tbprTaskTitleWrap:after {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        content: '';
        width: 80px;
        background: linear-gradient(90deg, rgba(244,244,244,0) 0%, rgba(244,244,244,1) 68%);
    }
}
</style>
