<template>
<div class="TaskInfo">
    <v-tabs v-model="tabInfoTask" background-color="transparent">
        <v-tab class="bg-gray-100 md:w-32 font-10 px-1  md:text-xs" value="list">Liste</v-tab>
        <div style="width: 1px" class="h-full bg-gray-300"></div>
        <v-tab class="bg-gray-100 md:w-32 font-10 px-1  md:text-xs" value="info">Info</v-tab>
    </v-tabs>
    <div class="mt-4">
        <v-card-text class="pb-4 px-0">
            <v-window v-model="tabInfoTask">
                <v-window-item value="info">
                    <div class="currProjectInd">
                        Informationen
                    </div>
                    <div class="pt-2">
                        <EditorMPM v-model="taskInfos" />
                    </div>
                </v-window-item>
                <v-window-item value="list">
                    <div class="fyCenter fxBetween mb-4">
                        <div class="currProjectInd">
                            Subtasks
                        </div>
                        <div class="fyCenter ml-6">
                            <v-btn @click="updateList(true)" class="delConfirm mr-3 h-20" color="error" :class="{'active' : deleteListWarn}">Löschen?</v-btn>
                            <v-btn @click="deleteListWarn = !deleteListWarn" variant="text" class="h-20" color="error">
                                <v-icon :icon="delWarnIcon()"></v-icon>
                            </v-btn>
                        </div>
                    </div>
                    <div class="fxEnd items-center mb-4 w-full">
                        <v-text-field class="mr-4" ref="tName" v-on:keyup.enter="add" v-model="nTask" hide-details label="Subtask"></v-text-field>
                        <v-btn variant="plain" class="opacity-100 font-bold" color="var(--cMPM)" @click="add" size="small">
                            <v-icon start icon="mdi-plus-circle-outline"></v-icon>
                            <span class="hidden md:inline-block">hinzufügen</span>
                        </v-btn>
                    </div>
                    <draggable :list="cTasks" v-bind="dragOptions" :component-data="{ tag: 'ul', name: 'flip-list', type: 'transition' }" :disabled="!enabled" item-key="name" class="list-group" ghost-class="ghost" @start="dragging = true" @end="dragging = false">
                        <template #item="{ element, idx }">
                            <div class="bg-white taskInfoTask shadow mb-4 flex rounded-md" :class="[{ 'not-draggable': !enabled }, {'active' : element.isDone }]">
                                <div class="flex items-center w-full justify-between">
                                    <div @click="element.isDone = !element.isDone" class="flex not-draggable items-center">
                                        <div class="checkWrapper w-16 cursor-pointer relative h-12 fCenter">
                                            <div class="border w-5 h-5 absCenter rounded-full border-mpm circle-icon"> </div>
                                            <v-icon color="var(--cMPM)" :class="{'active' : element.isDone }" class="iconDone" icon="md:check_circle"></v-icon>
                                        </div>
                                        <div class="tInfoTitle py-4">
                                            {{element.name}}
                                        </div>
                                    </div>
                                    <div class="mx-4">
                                        <v-icon @click="removeAt(idx)" start icon="md:delete"></v-icon>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </draggable>
                </v-window-item>
            </v-window>
        </v-card-text>
        <v-divider class="mb-4"></v-divider>
        <v-row class="fyCenter">
            <v-col cols="4" class="mb-2">

            </v-col>
            <v-col cols="8" class="mb-2 fxEnd">
                <v-btn @click="updateList(false)">
                    <v-icon start icon="mdi-plus-circle-outline"></v-icon>
                    <span> speichern</span>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</div>
</template>

<script>
import draggable from 'vuedraggable'
import { updateTaskState } from '@/graphql/mutations'
import EditorMPM from './EditorMPM.vue';

export default {
    components: {
        draggable,
        EditorMPM
    },
    props: {
        cTask: {
            type: Object
        }
    },
    data() {
        return {
            enabled: true,
            nTask: '',
            cTasks: [...this.cTask.Info.list],
            deleteListWarn: false,
            tabInfoTask: 'list',
            taskInfos: this.cTask.tInfo
        }
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        }
    },
    methods: {
        add() {
            if (this.nTask != '') {
                let n = {
                    "name": this.nTask,
                    "isDone": false
                }

                this.cTasks.push(n);
                this.nTask = '';
            }
        },
        removeAt(idx) {
            this.cTasks.splice(idx, 1);
        },
        delWarnIcon() {
            if (this.deleteListWarn) {
                return 'md:clear';
            } else {
                return 'mdi-trash-can';
            }
        },
        updateList(del) {
            console.log(this.cTask.ID);

            if (del === false) {
                this.add();
                let taskInput = {
                    "ID": this.cTask.id,
                    "data": {
                        "Info": {
                            "type": "sortlist",
                            "list": this.cTasks,
                        },
                        "tInfo": this.taskInfos
                    }
                };
                this.$apollo.mutate({
                    mutation: updateTaskState,
                    variables: taskInput
                }).then(() => {
                    this.$emit('close');
                });
            } else {
                let taskInput = {
                    "ID": this.cTask.id,
                    "data": {
                        "Info": {
                            "type": "",
                            "list": ""
                        },
                        "tInfo": this.taskInfos
                    }
                };
                this.$apollo.mutate({
                    mutation: updateTaskState,
                    variables: taskInput
                }).then(() => {
                    this.$emit('close');
                });
            }
        }
    }
}
</script>

<style>
.iconDone {
    opacity: 0;
    transition: .3s ease-in;
}

.iconDone.active {
    opacity: 1;
}

.delConfirm {
    width: 0;
    opacity: 0;
    pointer-events: none;
    transition: .2s ease-out;
}

.delConfirm.active {
    width: 120px;
    opacity: 1;
    pointer-events: all;
}

.taskInfoTask.active {
    opacity: 0.6;
}
</style>
