import gql from "graphql-tag";

export const updateTask = gql`
  mutation updateTask($id: ID!, $data: TaskInput!) {
    updateTask(id: $id, data: $data) {
      data {
        id
        attributes {
          Name
        }
      }
    }
  }
`;

export const updateTaskState = gql`
  mutation updateTaskState($ID: ID!, $data: TaskInput!) {
    updateTask(id: $ID, data: $data) {
      data {
        id
      }
    }
  }
`;

export const updateProject = gql`
  mutation updateProject($ID: ID!, $data: ProjectInput!) {
    updateProject(id: $ID, data: $data) {
      data {
        id
        attributes {
          Name
          WorkType
          Accounts
          Informationen
          Ansprechpartner {
            id
            Name
            Email
            Telefon
          }
        }
      }
    }
  }
`;

export const deleteTask = gql`
  mutation deleteTask($ID: ID!) {
    deleteTask(id: $ID) {
      data {
        id
      }
    }
  }
`;
export const safeWishlist = gql`
  mutation safeWishlist($id: ID!, $data: WishlistInput!) {
    updateWishlist(id: $id, data: $data) {
      data {
        id
        attributes {
          Category
          wishitems {
            id
            Name
            EstimateDate
            Cost
            Links
            Desc
            Type
            Prio
          }
        }
      }
    }
  }
`;

export const updateBirthday = gql`
  mutation updateBirthday($id: ID!, $data: BirthdayInput!) {
    updateBirthday(id: $id, data: $data) {
      data {
        id
        attributes {
          Date
          Birthdays
        }
      }
    }
  }
`;

export const updateFinance = gql`
  mutation updateFinance($id: ID!, $data: FinanceInput!) {
    updateFinance(id: $id, data: $data) {
      data {
        id
      }
    }
  }
`;

export const deleteFinance = gql`
  mutation deleteFinance($ID: ID!) {
    deleteFinance(id: $ID) {
      data {
        id
      }
    }
  }
`;
