import { createApp, provide, h } from "vue";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { createApolloProvider } from "@vue/apollo-option";
import axios from "axios";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store";
import "v-calendar/dist/style.css";
import { SetupCalendar, Calendar, DatePicker } from "v-calendar";
import Maska from "maska";
import { setContext } from "@apollo/client/link/context";
import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client/core";
import moment from "moment-timezone";

import { loadFonts } from "./plugins/webfontloader";
import "./assets/css/main.css";
import "./registerServiceWorker";

loadFonts();

const httpLink = createHttpLink({
  // You should use an absolute URL here
  // uri: "https://mpmback.devjb.de/" + "graphql",
  // uri: "http://localhost:1337/graphql",
  uri: "https://mpmbackv3.devjb.de/graphql",
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("jwt");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});

moment.locale("de");
moment.tz.setDefault("Europe/Berlin");

let app = createApp({
  router,
  store,
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },
  render: () => h(App),
  mounted() {
    document.title = "MPM Manager";
  },
  created() {
    const userString = localStorage.getItem("user");
    if (userString) {
      const userData = JSON.parse(userString);
      this.$store.commit("SET_USER_DATA", userData);
    }
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        console.log(error.response);
        if (error.response.status === 401) {
          this.$router.push("/");
          this.$store.dispatch("logout");
        }
        return Promise.reject(error);
      }
    );
  },
});

app.config.globalProperties.$moment = moment;

app
  .use(store)
  .use(router)
  .use(apolloProvider)
  .use(vuetify)
  .use(Maska)
  .use(SetupCalendar, {})
  .component("Calendar", Calendar)
  .component("DatePicker", DatePicker)
  .mount("#app");
