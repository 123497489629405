<template>
  <div class="openTaskWrap">
    <v-btn
      v-if="tasks.length"
      variant="tonal"
      @click="openTaskOv = true"
      color="var(--cMPM)"
      prepend-icon="mdi-alert-circle"
    >
      {{ tasks.length }}
    </v-btn>
    <v-dialog class="overlayCont smallOverlay" scrollable v-model="openTaskOv">
      <div class="ovCard small">
        <div class="ovCardTitleWrapper">
          <div class="fyCenter">
            <div class="ovTitle">Offene Tasks</div>
            <v-btn
              color="var(--cMPM)"
              variant="tonal"
              @click="bringTaskAll()"
              class="ml-4"
              :size="$store.state.theme.btnSmallEdit"
              prepend-icon="mdi-arrow-down-bold-box"
              >Alle</v-btn
            >
          </div>
          <div class="closeBtnWrapper">
            <v-btn @click="closeRef" color="var(--cMPM)" variant="text" icon="mdi-close-circle"></v-btn>
          </div>
        </div>
        <v-divider class="mb-4"></v-divider>
        <div v-for="t in tasks" :key="t" class="openTaskRow">
          <v-row class="px-6">
            <v-col cols="6" class="fyCenter" md="6">
              <div class="tbprTaskTitleWrap">
                {{ t.attributes.Name }}
              </div>
            </v-col>
            <v-col cols="6" class="fxEnd" md="6">
              <v-btn @click="deleteTask(t.id)" color="error" variant="text" icon="mdi-trash-can"></v-btn>
              <v-btn @click="bringTask(t)" color="var(--cMPM)" variant="text" icon="mdi-arrow-down-bold-box"></v-btn>
            </v-col>
          </v-row>
          <v-divider class="my-4"></v-divider>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { getOpenTasksByUser } from "@/graphql/queries";
import { deleteTask, updateTask } from "@/graphql/mutations";

export default {
  props: {
    uid: {
      type: Number,
      default: 1,
    },
    today: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tasks: [],
      openTaskOv: false,
    };
  },
  apollo: {
    tasks: {
      query: getOpenTasksByUser,
      variables() {
        return {
          id: parseInt(this.uid),
          today: this.today,
        };
      },
      context: {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt"),
        },
      },
      result(res) {
        this.tasks = [...res.data.tasks.data];
      },
      error(graphQLErrors) {
        console.log("errors", graphQLErrors);
      },
    },
  },
  methods: {
    deleteTask(id) {
      this.$apollo
        .mutate({
          mutation: deleteTask,
          context: {
            headers: {
              Authorization: "Bearer " + this.$store.state.user.jwt,
            },
          },
          variables: {
            ID: id,
          },
        })
        .then(() => {
          this.checkEmpty();
        });
    },
    bringTaskAll() {
      this.tasks.forEach((t) => {
        this.bringTask(t);
      });
      this.$apollo.queries.tasks.refetch();
    },
    bringTask(aTask) {
      let nTask = {};
      if (aTask.attributes.scStart != "" && aTask.attributes.scStart != null) {
        nTask.scStart = aTask.attributes.scStart;
      }
      if (aTask.attributes.scEnd != "" && aTask.attributes.scEnd != null) {
        nTask.scEnd = aTask.attributes.scEnd;
      }
      nTask.Name = aTask.attributes.Name;
      nTask.DueDate = this.$moment().format("YYYY-MM-DD");
      nTask.project = aTask.attributes.project.data.id;
      nTask.aUser = this.uid;
      this.$apollo
        .mutate({
          mutation: updateTask,
          context: {
            headers: {
              Authorization: "Bearer " + this.$store.state.user.jwt,
            },
          },
          variables: {
            id: aTask.id,
            data: nTask,
          },
        })
        .then(() => {
          this.checkEmpty();
        });
    },
    checkEmpty() {
      this.$apollo.queries.tasks.refetch().then(() => {
        if (this.tasks.length == 0) {
          this.openTaskOv = false;
        }
      });
    },
    closeRef() {
      this.openTaskOv = false;
    },
  },
};
</script>
