<template>
<div class="mpmLogoWrapper">
    <div v-if="type == 'full'" class="mpmLogoFull">
        <div class="text-mpm flex items-center font-semibold text-xl md:text-4xl">
            <div class="mymLogo">
                <svg xmlns="http://www.w3.org/2000/svg" width="289.322" height="433.19" viewBox="0 0 289.322 433.19" class="w-8 h-9 md:h-14 md:w-12">
                    <g id="mymLogo" transform="translate(-575.44 -229.924)">
                        <circle id="Ellipse_1" data-name="Ellipse 1" cx="81.909" cy="81.909" r="81.909" transform="translate(590.945 453.469)" fill="#fff"></circle>
                        <path id="Path_3" data-name="Path 3" d="M840.472,736.73a82.909,82.909,0,1,1,82.909-82.909,82.909,82.909,0,0,1-82.909,82.909Zm0-163.817a80.909,80.909,0,1,0,80.909,80.909A80.909,80.909,0,0,0,840.472,572.913Z" transform="translate(-167.619 -118.443)" fill="#3f3d56"></path>
                        <path id="Path_4" data-name="Path 4" d="M834.34,686.557c-.175,0-.349-.006-.524-.014a10.711,10.711,0,0,1-7.94-4.126l-13.688-17.6a10.716,10.716,0,0,1,1.878-15.04l.493-.383a10.715,10.715,0,0,1,15.043,1.88,7.816,7.816,0,0,0,11.837.576l27.793-29.341a10.732,10.732,0,0,1,15.152-.412l.456.434a10.714,10.714,0,0,1,.409,15.151l-43.131,45.526a10.7,10.7,0,0,1-7.777,3.348Z" transform="translate(-167.619 -118.443)" fill="#6c63ff"></path>
                        <path id="Path_5" data-name="Path 5" d="M630.005,640.052l-11.36,1.265-10.763-44.072,16.765-1.868Z" fill="#a0616a"></path>
                        <path id="Path_6" data-name="Path 6" d="M872.464,560.357c-.353-.351-.735-.693-1.116-1.018a103.641,103.641,0,0,0-9.176-6.795c-3.6-2.4-7.649-4.846-12.23-7.373q-3.326-1.837-7.03-3.75-1.855-.959-3.818-1.932a1.914,1.914,0,0,0-.172-.089l-.379-.192-27.568,22.757-1.958,1.539,5.078,6.262s-.442,4.979-.432,4.981l-5.041,12.58-.055.026-17.96,8.2L772.1,604l-9.941,4.541a19.1,19.1,0,0,0-10.022,10.828,19.853,19.853,0,0,0,.654,15.333c.222.486.472.973.737,1.441l4.131,25.972a156.324,156.324,0,0,0,14.231,44.71l6.309,24.312.958-.481,14.269-7.147,3.77-1.884.712-16.335c.081-1.862.092-3.734.026-5.6a72.006,72.006,0,0,0-.875-9.127l-8.181-50.941q2.178-.888,4.3-1.784,9.821-4.11,18.6-8.308c19.026-9.093,34.526-18.533,47.19-28.759q5.338-4.3,10.014-8.807,1.719-1.646,3.346-3.324c.258-.259.51-.514.758-.775a19.329,19.329,0,0,0,4.8-17.871A19.721,19.721,0,0,0,872.464,560.357Z" transform="translate(-167.619 -118.443)" fill="#2f2e41"></path>
                        <path id="Path_7" data-name="Path 7" d="M796.7,750.78l-12.3,1.359-1.93.211,1.256,7.3-14.29,13.96a4.629,4.629,0,0,0,4.014,7.877l20.294-3.476L796,770.192l2.6,6.989,7.644-1.308-6.563-25.421Z" transform="translate(-167.619 -118.443)" fill="#2f2e41"></path>
                        <path id="Path_8" data-name="Path 8" d="M808.4,497.915l10.529-11.166-14.853-32.5-2.209-11.415a8.631,8.631,0,0,0-14.09-4.913h0a8.631,8.631,0,0,0-1.123,11.944l8.2,10.251Z" transform="translate(-167.619 -118.443)" fill="#a0616a"></path>
                        <path id="Path_9" data-name="Path 9" d="M818.931,481.749l-12,12,2.3,17.545a13.953,13.953,0,0,0,10.061,9.331h0a13.953,13.953,0,0,0,15.2-6.395l26.991-46.265a14.243,14.243,0,0,0-4.592-19.36h0a14.243,14.243,0,0,0-20.4,5.75l-16.475,35.1Z" transform="translate(-167.619 -118.443)" fill="#e6e6e6"></path>
                        <path id="Path_10" data-name="Path 10" d="M806.842,658.826c-.62.631-1.242,1.255-1.871,1.88-1.251,1.249-2.52,2.487-3.793,3.71-.64.613-1.288,1.227-1.929,1.832l.005.022-.042.018-.013.011-1.068.551-2.657-3.913-1.225-1.8-1.732-2.553-3.172-4.669-16.7-24.586,14.98-7.733,1.18,2.3.007,0,.583,1.138,10.955,21.226,2.237,4.34.07.131L804,653.315l1.16,2.246,1.293,2.51Z" transform="translate(-167.619 -118.443)" fill="#a0616a"></path>
                        <path id="Path_11" data-name="Path 11" d="M803.747,650.857l-10.428,6.658-1.637,1.043,4.356,5.994L789.4,683.392a4.629,4.629,0,0,0,7.084,5.29l16.664-12.093-1.435-8.014,5.426,5.116,6.278-4.554-17.129-19.9Z" transform="translate(-167.619 -118.443)" fill="#2f2e41"></path>
                        <path id="Path_32" data-name="Path 32" d="M878.936,580.675c-1.038,1.666-2.1,3.325-3.166,4.969q-2.977,4.59-6.112,9.043c0,.008-.008.008-.015.015a21.378,21.378,0,0,1-4.014,2.931c-.061.045-.129.076-.189.114a14.8,14.8,0,0,1-3.378,1.356l-1.28.318c-1.53.364-3.045.674-4.552.947q-4.022.716-7.968,1.03c-.212.023-.424.038-.636.053-10.838.8-21.389-.485-32.4-2.583-5.438-1.03-10.99-2.265-16.739-3.545-1.878-.424-3.764-.841-5.673-1.257-.144-.03-.3-.068-.447-.1-2.727-.6-5.5-1.189-8.339-1.772-3.575-.735-1.742-.777-5.575-1.451,0,0,8.842,8.979,10.312,12.872,1.636,4.787,2.931,8.574.2,16.458l8.491,16.669-29.52,5.006c-16.27-31.881-29.272-52.874-23.464-65.238.023-.576.068-1.159.144-1.734a19.843,19.843,0,0,1,7.551-13.361,19.109,19.109,0,0,1,14.232-3.893l5.794.75,44.937-2.183,1.765-7.2.326-.121,10.354-3.795,35.81-13.126.091-.03,2.356-.863.28.454c.083.136.167.273.242.409a70.191,70.191,0,0,1,5.658,13.133,206.18,206.18,0,0,1,5.945,22.7q.1.5.182.909l.023.083c.023.129.038.212.045.235C881.026,572.32,880.451,576.516,878.936,580.675Z" transform="translate(-167.619 -118.443)" fill="#2f2e41"></path>
                        <line id="Line_1" data-name="Line 1" x1="0.712" transform="translate(616.407 550.991)" fill="#2f2e41"></line>
                        <path id="Path_33" data-name="Path 33" d="M871.381,440.723l-.259-14.354-22.741-2.646L846,435.844,822.1,458.3c-16.993,20.775-4.645,35.1-3.906,37.232l2.1,26.2,1.322,9.1-5.162,24.909L881.6,569.719c2.086-17.221-.237-33.222-13.839-46.1l6.873-21.385h0a43.913,43.913,0,0,0,6.392-18.421l.247-2.236C887.35,462.651,883.753,456.28,871.381,440.723Z" transform="translate(-167.619 -118.443)" fill="#e6e6e6"></path>
                        <path id="Path_34" data-name="Path 34" d="M903.286,395.861c.015.371.023.742.023,1.106A25.426,25.426,0,0,1,870,421.1l-4.673-1.522c-.038-.015-.083-.023-.121-.038l-7.7-2.515a5.272,5.272,0,0,1,.015-.977c-11.49-4.908,4.393-21.033,8.339-33.144.613-1.886-20.48-.477-19.48-2.09-.22-.114-.439-.227-.644-.341a31.216,31.216,0,0,1,34.477-13.436c.167.038.341.091.507.136,14.966.371,22.828,10.8,22.828,24.343A37.133,37.133,0,0,1,903.286,395.861Z" transform="translate(-167.619 -118.443)" fill="#2f2e41"></path>
                        <path id="Path_35" data-name="Path 35" d="M890.32,401.193a24.4,24.4,0,0,1-30.758,15.625q-1.045-.341-2.045-.773a24.39,24.39,0,0,1-13.58-29.978,24.144,24.144,0,0,1,2.439-5.256,24.393,24.393,0,0,1,43.944,20.382Z" transform="translate(-167.619 -118.443)" fill="#a0616a"></path>
                        <path id="Path_36" data-name="Path 36" d="M865.321,419.573a.846.846,0,0,1-.12-.04l-7.7-2.51c-.02-.33-14.16,9.03-14.12,8.7.22-2.53,16.15-14.87,18.53-17.74a14.759,14.759,0,0,0-6.48-23.44,1.184,1.184,0,0,1-.2-.07c-1.49-.52-2.92-1.05-4.26-1.58-.66-2.75-1-1.84-2.59-4.17.29,2.12-2.4,3.72-2.44,5.86-.55-.25-1.07-.51-1.57-.77-.22-.12-.43-.23-.64-.34a10.618,10.618,0,0,1-3.93-3.14c3.85-11.83,18.58-21.3,30.41-17.43l4.47,1.45a22.434,22.434,0,0,1,13.03,11.09,25.257,25.257,0,0,1,7.58,9.49C905.071,404.583,886.181,426.273,865.321,419.573Z" transform="translate(-167.619 -118.443)" fill="#2f2e41"></path>
                        <circle id="Ellipse_5" data-name="Ellipse 5" cx="10.604" cy="10.604" r="10.604" transform="translate(719.444 247.039)" fill="#2f2e41"></circle>
                        <path id="Path_37" data-name="Path 37" d="M1032.381,397.713a70.322,70.322,0,0,1-37.12,47.73c-1.19.59-2.4,1.14-3.62,1.65a23.986,23.986,0,0,0-4.07-13.91,42.677,42.677,0,0,1,.16,10.04,42.248,42.248,0,0,1-.95,5.69,70.484,70.484,0,0,1-24.34,3.51,23.65,23.65,0,0,0-3.87-10.24,42.674,42.674,0,0,1,.16,10.04,68.9,68.9,0,0,1-23.93-6.41c-3.91-1.86-7.75-4.19-10.27-7.71-3.35-4.69-3.91-10.88-3.09-16.58s2.87-11.16,4.3-16.74,2.24-11.55.56-17.06-6.36-10.43-12.11-10.86c-2.62-.2-5.25.52-7.87.28s-5.46-2-5.52-4.63l2.51-4.08c1.06-7.14,4.7-14.22,10.94-17.85s15.19-2.86,19.64,2.82c3.75,4.78,3.61,11.42,4.84,17.37,2.86,13.82,13.91,24.94,26.75,30.8s27.31,7.08,41.43,6.84c4.84-.09,9.76-.35,14.36-1.88S1030.181,402.033,1032.381,397.713Z" transform="translate(-167.619 -118.443)" fill="#2f2e41"></path>
                        <path id="Path_38" data-name="Path 38" d="M791.9,461.441l-4.152-49.522a6.42,6.42,0,0,1,5.855-6.926l10.793-.905a6.42,6.42,0,0,1,6.926,5.855l4.152,49.522a6.42,6.42,0,0,1-5.855,6.926l-10.793.905A6.42,6.42,0,0,1,791.9,461.441Z" transform="translate(-167.619 -118.443)" fill="#3f3d56"></path>
                        <path id="Path_39" data-name="Path 39" d="M795.076,407.413a3.46,3.46,0,0,0-3.155,3.733l4.152,49.522a3.46,3.46,0,0,0,3.733,3.155l10.793-.9a3.46,3.46,0,0,0,3.155-3.733L809.6,409.663a3.46,3.46,0,0,0-3.733-3.155Z" transform="translate(-167.619 -118.443)" fill="#6c63ff"></path>
                        <path id="Path_40" data-name="Path 40" d="M813.273,478.433l10.508-4.01-6.1-23.352-1.069-11.577a8.631,8.631,0,0,0-13.535-6.283h0a8.631,8.631,0,0,0-2.3,11.775L807.922,456Z" transform="translate(-167.619 -118.443)" fill="#a0616a"></path>
                        <path id="Path_41" data-name="Path 41" d="M824.2,470.252l-14.01,5.347,6.974,32.745a13.953,13.953,0,0,0,9.088,10.281h0A13.953,13.953,0,0,0,842,513.764L873.437,470.4a14.242,14.242,0,0,0-2.655-19.719h0a14.242,14.242,0,0,0-20.871,3.7l-19.867,33.3Z" transform="translate(-167.619 -118.443)" fill="#e6e6e6"></path>
                        <path id="Path_42" data-name="Path 42" d="M812.8,487.879l7.082,28.759,1.469,1.788a11.448,11.448,0,0,0,18.656-1.367l22.444-37.316-20.235,28a11.448,11.448,0,0,1-19.347-1.257Z" transform="translate(-167.619 -118.443)" opacity="0.2"></path>
                    </g>
                </svg>
            </div>
            <div>
                MPM
                <div class="text-xs hidden lg:block">My Personal Manager</div>
            </div>
        </div>
    </div>
    <div  v-if="type == 'icon'" class="mpmLogo fCenter">
        <svg xmlns="http://www.w3.org/2000/svg" width="289.322" height="433.19" viewBox="0 0 289.322 433.19">
            <g id="mymLogo" transform="translate(-575.44 -229.924)">
                <circle id="Ellipse_1" data-name="Ellipse 1" cx="81.909" cy="81.909" r="81.909" transform="translate(590.945 453.469)" fill="#fff"></circle>
                <path id="Path_3" data-name="Path 3" d="M840.472,736.73a82.909,82.909,0,1,1,82.909-82.909,82.909,82.909,0,0,1-82.909,82.909Zm0-163.817a80.909,80.909,0,1,0,80.909,80.909A80.909,80.909,0,0,0,840.472,572.913Z" transform="translate(-167.619 -118.443)" fill="#3f3d56"></path>
                <path id="Path_4" data-name="Path 4" d="M834.34,686.557c-.175,0-.349-.006-.524-.014a10.711,10.711,0,0,1-7.94-4.126l-13.688-17.6a10.716,10.716,0,0,1,1.878-15.04l.493-.383a10.715,10.715,0,0,1,15.043,1.88,7.816,7.816,0,0,0,11.837.576l27.793-29.341a10.732,10.732,0,0,1,15.152-.412l.456.434a10.714,10.714,0,0,1,.409,15.151l-43.131,45.526a10.7,10.7,0,0,1-7.777,3.348Z" transform="translate(-167.619 -118.443)" fill="#6c63ff"></path>
                <path id="Path_5" data-name="Path 5" d="M630.005,640.052l-11.36,1.265-10.763-44.072,16.765-1.868Z" fill="#a0616a"></path>
                <path id="Path_6" data-name="Path 6" d="M872.464,560.357c-.353-.351-.735-.693-1.116-1.018a103.641,103.641,0,0,0-9.176-6.795c-3.6-2.4-7.649-4.846-12.23-7.373q-3.326-1.837-7.03-3.75-1.855-.959-3.818-1.932a1.914,1.914,0,0,0-.172-.089l-.379-.192-27.568,22.757-1.958,1.539,5.078,6.262s-.442,4.979-.432,4.981l-5.041,12.58-.055.026-17.96,8.2L772.1,604l-9.941,4.541a19.1,19.1,0,0,0-10.022,10.828,19.853,19.853,0,0,0,.654,15.333c.222.486.472.973.737,1.441l4.131,25.972a156.324,156.324,0,0,0,14.231,44.71l6.309,24.312.958-.481,14.269-7.147,3.77-1.884.712-16.335c.081-1.862.092-3.734.026-5.6a72.006,72.006,0,0,0-.875-9.127l-8.181-50.941q2.178-.888,4.3-1.784,9.821-4.11,18.6-8.308c19.026-9.093,34.526-18.533,47.19-28.759q5.338-4.3,10.014-8.807,1.719-1.646,3.346-3.324c.258-.259.51-.514.758-.775a19.329,19.329,0,0,0,4.8-17.871A19.721,19.721,0,0,0,872.464,560.357Z" transform="translate(-167.619 -118.443)" fill="#2f2e41"></path>
                <path id="Path_7" data-name="Path 7" d="M796.7,750.78l-12.3,1.359-1.93.211,1.256,7.3-14.29,13.96a4.629,4.629,0,0,0,4.014,7.877l20.294-3.476L796,770.192l2.6,6.989,7.644-1.308-6.563-25.421Z" transform="translate(-167.619 -118.443)" fill="#2f2e41"></path>
                <path id="Path_8" data-name="Path 8" d="M808.4,497.915l10.529-11.166-14.853-32.5-2.209-11.415a8.631,8.631,0,0,0-14.09-4.913h0a8.631,8.631,0,0,0-1.123,11.944l8.2,10.251Z" transform="translate(-167.619 -118.443)" fill="#a0616a"></path>
                <path id="Path_9" data-name="Path 9" d="M818.931,481.749l-12,12,2.3,17.545a13.953,13.953,0,0,0,10.061,9.331h0a13.953,13.953,0,0,0,15.2-6.395l26.991-46.265a14.243,14.243,0,0,0-4.592-19.36h0a14.243,14.243,0,0,0-20.4,5.75l-16.475,35.1Z" transform="translate(-167.619 -118.443)" fill="#e6e6e6"></path>
                <path id="Path_10" data-name="Path 10" d="M806.842,658.826c-.62.631-1.242,1.255-1.871,1.88-1.251,1.249-2.52,2.487-3.793,3.71-.64.613-1.288,1.227-1.929,1.832l.005.022-.042.018-.013.011-1.068.551-2.657-3.913-1.225-1.8-1.732-2.553-3.172-4.669-16.7-24.586,14.98-7.733,1.18,2.3.007,0,.583,1.138,10.955,21.226,2.237,4.34.07.131L804,653.315l1.16,2.246,1.293,2.51Z" transform="translate(-167.619 -118.443)" fill="#a0616a"></path>
                <path id="Path_11" data-name="Path 11" d="M803.747,650.857l-10.428,6.658-1.637,1.043,4.356,5.994L789.4,683.392a4.629,4.629,0,0,0,7.084,5.29l16.664-12.093-1.435-8.014,5.426,5.116,6.278-4.554-17.129-19.9Z" transform="translate(-167.619 -118.443)" fill="#2f2e41"></path>
                <path id="Path_32" data-name="Path 32" d="M878.936,580.675c-1.038,1.666-2.1,3.325-3.166,4.969q-2.977,4.59-6.112,9.043c0,.008-.008.008-.015.015a21.378,21.378,0,0,1-4.014,2.931c-.061.045-.129.076-.189.114a14.8,14.8,0,0,1-3.378,1.356l-1.28.318c-1.53.364-3.045.674-4.552.947q-4.022.716-7.968,1.03c-.212.023-.424.038-.636.053-10.838.8-21.389-.485-32.4-2.583-5.438-1.03-10.99-2.265-16.739-3.545-1.878-.424-3.764-.841-5.673-1.257-.144-.03-.3-.068-.447-.1-2.727-.6-5.5-1.189-8.339-1.772-3.575-.735-1.742-.777-5.575-1.451,0,0,8.842,8.979,10.312,12.872,1.636,4.787,2.931,8.574.2,16.458l8.491,16.669-29.52,5.006c-16.27-31.881-29.272-52.874-23.464-65.238.023-.576.068-1.159.144-1.734a19.843,19.843,0,0,1,7.551-13.361,19.109,19.109,0,0,1,14.232-3.893l5.794.75,44.937-2.183,1.765-7.2.326-.121,10.354-3.795,35.81-13.126.091-.03,2.356-.863.28.454c.083.136.167.273.242.409a70.191,70.191,0,0,1,5.658,13.133,206.18,206.18,0,0,1,5.945,22.7q.1.5.182.909l.023.083c.023.129.038.212.045.235C881.026,572.32,880.451,576.516,878.936,580.675Z" transform="translate(-167.619 -118.443)" fill="#2f2e41"></path>
                <line id="Line_1" data-name="Line 1" x1="0.712" transform="translate(616.407 550.991)" fill="#2f2e41"></line>
                <path id="Path_33" data-name="Path 33" d="M871.381,440.723l-.259-14.354-22.741-2.646L846,435.844,822.1,458.3c-16.993,20.775-4.645,35.1-3.906,37.232l2.1,26.2,1.322,9.1-5.162,24.909L881.6,569.719c2.086-17.221-.237-33.222-13.839-46.1l6.873-21.385h0a43.913,43.913,0,0,0,6.392-18.421l.247-2.236C887.35,462.651,883.753,456.28,871.381,440.723Z" transform="translate(-167.619 -118.443)" fill="#e6e6e6"></path>
                <path id="Path_34" data-name="Path 34" d="M903.286,395.861c.015.371.023.742.023,1.106A25.426,25.426,0,0,1,870,421.1l-4.673-1.522c-.038-.015-.083-.023-.121-.038l-7.7-2.515a5.272,5.272,0,0,1,.015-.977c-11.49-4.908,4.393-21.033,8.339-33.144.613-1.886-20.48-.477-19.48-2.09-.22-.114-.439-.227-.644-.341a31.216,31.216,0,0,1,34.477-13.436c.167.038.341.091.507.136,14.966.371,22.828,10.8,22.828,24.343A37.133,37.133,0,0,1,903.286,395.861Z" transform="translate(-167.619 -118.443)" fill="#2f2e41"></path>
                <path id="Path_35" data-name="Path 35" d="M890.32,401.193a24.4,24.4,0,0,1-30.758,15.625q-1.045-.341-2.045-.773a24.39,24.39,0,0,1-13.58-29.978,24.144,24.144,0,0,1,2.439-5.256,24.393,24.393,0,0,1,43.944,20.382Z" transform="translate(-167.619 -118.443)" fill="#a0616a"></path>
                <path id="Path_36" data-name="Path 36" d="M865.321,419.573a.846.846,0,0,1-.12-.04l-7.7-2.51c-.02-.33-14.16,9.03-14.12,8.7.22-2.53,16.15-14.87,18.53-17.74a14.759,14.759,0,0,0-6.48-23.44,1.184,1.184,0,0,1-.2-.07c-1.49-.52-2.92-1.05-4.26-1.58-.66-2.75-1-1.84-2.59-4.17.29,2.12-2.4,3.72-2.44,5.86-.55-.25-1.07-.51-1.57-.77-.22-.12-.43-.23-.64-.34a10.618,10.618,0,0,1-3.93-3.14c3.85-11.83,18.58-21.3,30.41-17.43l4.47,1.45a22.434,22.434,0,0,1,13.03,11.09,25.257,25.257,0,0,1,7.58,9.49C905.071,404.583,886.181,426.273,865.321,419.573Z" transform="translate(-167.619 -118.443)" fill="#2f2e41"></path>
                <circle id="Ellipse_5" data-name="Ellipse 5" cx="10.604" cy="10.604" r="10.604" transform="translate(719.444 247.039)" fill="#2f2e41"></circle>
                <path id="Path_37" data-name="Path 37" d="M1032.381,397.713a70.322,70.322,0,0,1-37.12,47.73c-1.19.59-2.4,1.14-3.62,1.65a23.986,23.986,0,0,0-4.07-13.91,42.677,42.677,0,0,1,.16,10.04,42.248,42.248,0,0,1-.95,5.69,70.484,70.484,0,0,1-24.34,3.51,23.65,23.65,0,0,0-3.87-10.24,42.674,42.674,0,0,1,.16,10.04,68.9,68.9,0,0,1-23.93-6.41c-3.91-1.86-7.75-4.19-10.27-7.71-3.35-4.69-3.91-10.88-3.09-16.58s2.87-11.16,4.3-16.74,2.24-11.55.56-17.06-6.36-10.43-12.11-10.86c-2.62-.2-5.25.52-7.87.28s-5.46-2-5.52-4.63l2.51-4.08c1.06-7.14,4.7-14.22,10.94-17.85s15.19-2.86,19.64,2.82c3.75,4.78,3.61,11.42,4.84,17.37,2.86,13.82,13.91,24.94,26.75,30.8s27.31,7.08,41.43,6.84c4.84-.09,9.76-.35,14.36-1.88S1030.181,402.033,1032.381,397.713Z" transform="translate(-167.619 -118.443)" fill="#2f2e41"></path>
                <path id="Path_38" data-name="Path 38" d="M791.9,461.441l-4.152-49.522a6.42,6.42,0,0,1,5.855-6.926l10.793-.905a6.42,6.42,0,0,1,6.926,5.855l4.152,49.522a6.42,6.42,0,0,1-5.855,6.926l-10.793.905A6.42,6.42,0,0,1,791.9,461.441Z" transform="translate(-167.619 -118.443)" fill="#3f3d56"></path>
                <path id="Path_39" data-name="Path 39" d="M795.076,407.413a3.46,3.46,0,0,0-3.155,3.733l4.152,49.522a3.46,3.46,0,0,0,3.733,3.155l10.793-.9a3.46,3.46,0,0,0,3.155-3.733L809.6,409.663a3.46,3.46,0,0,0-3.733-3.155Z" transform="translate(-167.619 -118.443)" fill="#6c63ff"></path>
                <path id="Path_40" data-name="Path 40" d="M813.273,478.433l10.508-4.01-6.1-23.352-1.069-11.577a8.631,8.631,0,0,0-13.535-6.283h0a8.631,8.631,0,0,0-2.3,11.775L807.922,456Z" transform="translate(-167.619 -118.443)" fill="#a0616a"></path>
                <path id="Path_41" data-name="Path 41" d="M824.2,470.252l-14.01,5.347,6.974,32.745a13.953,13.953,0,0,0,9.088,10.281h0A13.953,13.953,0,0,0,842,513.764L873.437,470.4a14.242,14.242,0,0,0-2.655-19.719h0a14.242,14.242,0,0,0-20.871,3.7l-19.867,33.3Z" transform="translate(-167.619 -118.443)" fill="#e6e6e6"></path>
                <path id="Path_42" data-name="Path 42" d="M812.8,487.879l7.082,28.759,1.469,1.788a11.448,11.448,0,0,0,18.656-1.367l22.444-37.316-20.235,28a11.448,11.448,0,0,1-19.347-1.257Z" transform="translate(-167.619 -118.443)" opacity="0.2"></path>
            </g>
        </svg>
    </div>
</div>
</template>
<style>
.mpmLogo {
   width: 100%;
   height: 100%;
}
</style>
<script>
export default {
    props: {
        type: {
            type: String,
            default: 'icon'
        }
    },
    data() {
        return {}
    }
}
</script>
