<template>
<div class="taskCompWrapper">
    <v-row>
        <v-col cols="12" class="pb-0">
            <div class="currProjectInd">
                {{cTask.Project.Name}}
            </div>
        </v-col>
        <v-col cols="12" class="">
            <div class="tNameInputWrapper">
                <v-text-field ref="tName" v-on:keyup.enter="updateTask" v-model="cTask.Name" hide-details label="Task"></v-text-field>
            </div>
        </v-col>
        <v-col cols="12" class="mb-2">
            <div class="tDueDateInputWrapper">
                <DatePicker color="gray" v-model="cTask.DueDate" mode="date" locale="de" is-expanded />
            </div>
        </v-col>
        <v-col cols="6" class="mb-2">
            <v-text-field v-on:keyup.enter="updateTask" ref="startTimeTask" @input="fillTime('start', cTask.tStart)" placeholder="12:00" v-maska="'##:##'" v-model="cTask.tStart" hide-details label="Startzeit"></v-text-field>
        </v-col>
        <v-col cols="6" sm="6" class="mb-2">
            <v-text-field ref="endTimeTask" v-on:keyup.enter="updateTask" placeholder="13:00" v-maska="'##:##'" v-model="cTask.tEnd" hide-details label="Endzeit"></v-text-field>
        </v-col>
    </v-row>
    <v-divider class="my-4"></v-divider>
    <v-row class="fyCenter">
        <v-col cols="4" class="mb-2">
            <v-btn v-if="cTask.uid != -1" @click="deleteTask" variant="text" class="h-20" color="error" icon="mdi-trash-can"></v-btn>
        </v-col>
        <v-col cols="8" class="mb-2 fxEnd">
            <v-btn @click="updateTask">
                <v-icon start icon="mdi-plus-circle-outline"></v-icon>
                <span v-if="cTask.uid == -1">Task</span>
                <span v-else> bearbeiten</span>
            </v-btn>
        </v-col>
    </v-row>
</div>
</template>

<script>
import { createTask } from '@/graphql/create'
import { deleteTask, updateTask } from '@/graphql/mutations'
import formatDate from '@/composables/timeFormat';

export default {
    props: {
        task: {
            type: Object,
            default () {
                return {
                    uid: -1,
                    Name: '',
                    Project: {
                        Name: 'My Little Project',
                        pid: -1
                    },
                    DueDate: '',
                    tStart: '',
                    tEnd: '',
                    aUser: ''

                }
            }
        }

    },
    data() {
        return {
            cTask: this.task,
        }
    },
    mounted() {
        this.$nextTick(() => this.$refs.tName.focus());
    },
    methods: {
        updateTask() {
            let nTask = {};
            if (this.cTask.tStart != '') {
                nTask.scStart = this.cTask.tStart + ':00.000';
            }
            if (this.cTask.tEnd != '') {
                nTask.scEnd = this.cTask.tEnd + ':00.000';
            }
            nTask.Name = this.cTask.Name;
            nTask.publishedAt = formatDate('', 'iso');
            nTask.DueDate = this.$moment(this.cTask.DueDate).format('YYYY-MM-DD');
            nTask.project = this.cTask.Project.pid;
            nTask.aUser = this.cTask.aUser;
            if (this.cTask.uid == -1) {
                this.$apollo.mutate({
                        mutation: createTask,
                        context: {
                            headers: {
                                "Authorization": 'Bearer ' + this.$store.state.user.jwt
                            },
                        },
                        variables: {
                            "data": nTask
                        },

                    })
                    .then((res) => {
                        this.$emit('tSuccess', res);
                    });
            } else {
                this.$apollo.mutate({
                        mutation: updateTask,
                        context: {
                            headers: {
                                "Authorization": 'Bearer ' + this.$store.state.user.jwt
                            },
                        },
                        variables: {
                            "id": this.cTask.uid,
                            "data": nTask
                        },

                    })
                    .then((res) => {
                        this.$emit('tSuccess', res);
                    });
            }
        },
        deleteTask() {
            this.$apollo.mutate({
                    mutation: deleteTask,
                    context: {
                        headers: {
                            "Authorization": 'Bearer ' + this.$store.state.user.jwt
                        },
                    },
                    variables: {
                        "ID": this.cTask.uid
                    },

                })
                .then((res) => {
                    this.$emit('tSuccess', res);
                });
        },
        fillTime(type, input) {
            if (type == 'start') {
                if (input.length == 1) {
                    if (input > 3) {
                        this.cTask.tStart = '0' + input + ':00';
                        this.$nextTick(() => this.setCursorPosition(this.$refs.startTimeTask, 2));
                    } else {
                        this.cTask.tStart = input + '0:00';
                        this.$nextTick(() => this.setCursorPosition(this.$refs.startTimeTask, 1));
                    }
                }
            }
            if (type == 'end') {
                if (input.length == 1) {
                    if (input > 3) {
                        this.cTask.tEnd = '0' + input + ':00';
                        this.$nextTick(() => this.setCursorPosition(this.$refs.endTimeTask, 2));
                    } else {
                        this.cTask.tEnd = input + '0:00';
                        this.$nextTick(() => this.setCursorPosition(this.$refs.endTimeTask, 1));
                    }
                }
            }
        },
        setCursorPosition(el, pos) {
            el.focus();
            el.setSelectionRange(pos, pos);
        },
    }
}
</script>
