<template>
<div class="mpmEditorWrap">
    <div v-if="editor">
        <bubble-menu class="bubble-menu" :tippy-options="{ duration: 100 }" :editor="editor">
            <v-menu class="" open-on-hover>
                <template v-slot:activator="{ props }">
                    <v-btn class="bg-gray-200 shadow-lg" size="small" variant="tonal" v-bind="props">
                        Stile
                    </v-btn>
                </template>
                <v-card class="ovMenuTT">
                    <v-btn size="x-small" icon="mdi-format-paragraph" variant="flat" :class="{ 'is-active': editor.isActive('paragraph') }">
                    </v-btn>
                    <v-btn size="x-small" icon="mdi-format-bold" variant="flat" @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
                    </v-btn>
                    <v-btn size="x-small" icon="mdi-format-italic" variant="flat" @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
                    </v-btn>
                    <div class="mnTtDivider"></div>
                    <v-btn size="x-small" icon="mdi-format-header-1" variant="flat" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
                    </v-btn>
                    <v-btn size="x-small" icon="mdi-format-header-2" variant="flat" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
                    </v-btn>
                    <v-btn size="x-small" icon="mdi-format-header-3" variant="flat" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
                    </v-btn>
                    <div class="mnTtDivider"></div>
                    <v-btn size="x-small" icon="mdi-format-list-bulleted" variant="flat" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
                    </v-btn>
                    <v-btn size="x-small" icon="mdi-format-list-numbered" variant="flat" @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
                    </v-btn>
                    <div class="mnTtDivider"></div>
                    <v-btn size="x-small" icon="mdi-link-variant" variant="flat" @click="setLink" :class="{ 'is-active': editor.isActive('link') }">
                    </v-btn>
                    <v-btn size="x-small" icon="mdi-link-variant-off" variant="flat" @click="editor.chain().focus().unsetLink().run()" :disabled="!editor.isActive('link')">
                    </v-btn>
                    <div class="mnTtDivider"></div>
                    <v-btn size="x-small" icon="mdi-code-braces" variant="flat" @click="editor.chain().focus().toggleCodeBlock().run()" :class="{ 'is-active': editor.isActive('codeBlock') }">
                    </v-btn>
                    <v-btn size="x-small" icon="mdi-minus" variant="flat" @click="editor.chain().focus().setHorizontalRule().run()">
                    </v-btn>
                    <v-btn size="x-small" icon="mdi-format-page-break" variant="flat" @click="editor.chain().focus().setHardBreak().run()">
                    </v-btn>
                    <div class="mnTtDivider"></div>
                    <v-btn size="x-small" icon="mdi-undo-variant" variant="flat" @click="editor.chain().focus().undo().run()" :disabled="!editor.can().chain().focus().undo().run()">
                    </v-btn>
                </v-card>
            </v-menu>
        </bubble-menu>
    </div>
    <editor-content class="mt-4 py-1 px-2 editorFieldTT border-2 rounded border-gray-400" :editor="editor" />
</div>
</template>

<script>
import StarterKit from '@tiptap/starter-kit'
import { Editor, EditorContent, BubbleMenu } from '@tiptap/vue-3'
import Link from '@tiptap/extension-link'

export default {
    components: {
        EditorContent,
        BubbleMenu
    },

    props: {
        modelValue: {
            type: String,
            default: '',
        },
        editTitle: {
            type: String,
            default: ''
        }
    },

    emits: ['update:modelValue'],

    data() {
        return {
            editor: null,
            inEdit: false,
        }
    },
    computed: {
        showMenu() {
            if (this.editTitle == '') {
                return true;
            } else {
                return false;
            }
        }
    },
    watch: {
        modelValue(value) {
            // HTML
            const isSame = this.editor.getHTML() === value

            // JSON
            // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

            if (isSame) {
                return
            }

            this.editor.commands.setContent(value, false)
        },
    },

    mounted() {
        this.editor = new Editor({
            extensions: [
                StarterKit, Link
            ],
            content: this.modelValue,
            onUpdate: () => {
                // HTML
                this.$emit('update:modelValue', this.editor.getHTML())

                // JSON
                // this.$emit('update:modelValue', this.editor.getJSON())
            },
        })
    },
    methods: {
        setLink() {
            const previousUrl = this.editor.getAttributes('link').href
            const url = window.prompt('URL', previousUrl)

            // cancelled
            if (url === null) {
                return
            }

            // empty
            if (url === '') {
                this.editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .unsetLink()
                    .run()

                return
            }

            // update link
            this.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .setLink({ href: url })
                .run()
        },
    },

    beforeUnmount() {
        this.editor.destroy()
    },
}
</script>

<style>
/* Basic editor styles */
.ProseMirror>*+* {
    margin-top: 0.75em;
}

.ProseMirror ul,
.ProseMirror ol {
    padding: 0 1rem;
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
    line-height: 1.1;
}

.ProseMirror code {
    background-color: rgba(97, 97, 97, 0.1);
    color: #616161;
}

.ProseMirror pre {
    background: #0d0d0d;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
}

.ProseMirror pre code {
    color: inherit;
    padding: 0;
    background: none;
    font-size: 0.8rem;
}

.ProseMirror img {
    max-width: 100%;
    height: auto;
}

.ProseMirror blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(13, 13, 13, 0.1);
}

.ProseMirror hr {
    border: none;
    border-top: 2px solid rgba(13, 13, 13, 0.1);
    margin: 2rem 0;
}
</style>
