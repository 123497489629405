<template>
  <div>
    <div v-if="!loading" class="">
      <div v-for="(d, i) in tasksByDay" :key="d" class="taskOvCard" :class="checkView(d.view)">
        <v-row>
          <v-col cols="12" class="pb-2 pt-4 md:pt-0">
            <div class="fyCenter fxBetween">
              <div class="dayListTitleWrapper">
                {{ $moment(d.date).format("dddd") }}, {{ $moment(d.date).format("DD.MM.YYYY") }}
              </div>
              <div class="selectOptn">
                <v-btn
                  color="surface"
                  @click="changeView(d.view, i)"
                  density="compact"
                  variant="flat"
                  class="text-mpm"
                  :icon="d.view"
                >
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col v-for="w in twWorkTypes" :key="w" cols="12" md="6">
            <div class="taskByWorkWrapper max-w-full">
              <div class="tsCatTitleWrapper">
                <div class="taskCatTitle">
                  {{ w.Name }}
                </div>
                <div class="progressWorkType">
                  {{ progCheck(acProDay(i, w.value), "worktype", "done") }} /
                  {{ progCheck(acProDay(i, w.value), "worktype", "all") }}
                </div>
              </div>
              <div class="mt-3">
                <v-btn
                  variant="plain"
                  class="opacity-100 font-bold"
                  color="var(--cMPM)"
                  @click="openModal('newProject', w, i)"
                  size="small"
                >
                  <v-icon start icon="mdi-plus-circle-outline"></v-icon>
                  Projekt hinzufügen
                </v-btn>
              </div>
              <div class="tasksByProjectWrapper mt-6">
                <div
                  v-for="p in acProDay(i, w.value)"
                  :key="p"
                  class="tbprWrapper"
                  :class="{ isDone: progCheck(p.tasks, 'project', 'isDone') }"
                >
                  <v-row no-gutters class="tbprTitleWrapper fyCenter">
                    <v-col cols="6">
                      <div class="tbprTitleSlot">
                        <div class="tbprTitle py-1">
                          {{ p.Name }}
                        </div>
                      </div>
                    </v-col>

                    <v-col class="fxEnd fyCenter pl-4 md:pl-0" cols="6">
                      <div class="projectNavigation fyCenter">
                        <v-btn
                          @click="(showProjectID = p.id), (showProject = true)"
                          class="mr-3 md:mr-12"
                          variant="plain"
                          color="#FFF"
                          size="xs-small"
                        >
                          <v-icon icon="md:assessment"></v-icon>
                        </v-btn>
                        <v-btn
                          class="mr-6"
                          variant="plain"
                          color="#FFF"
                          @click="createTaskOV(p, d - 1)"
                          size="xs-small"
                        >
                          <v-icon icon="md:add_box"></v-icon>
                        </v-btn>
                      </div>
                      <div class="tbprEditSlot">
                        <div class="progressProject fxEnd">
                          {{ progCheck(p.tasks, "project", "done") }} / {{ progCheck(p.tasks, "project", "all") }}
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <div class="tbprTasksWrapper">
                    <div
                      v-for="t in p.tasks"
                      :key="t"
                      class="tbprTaskWrap"
                      :class="{ isDoneTask: t.attributes.isDone }"
                    >
                      <v-row class="fyCenter max-w-full" no-gutters>
                        <v-col cols="7" md="6">
                          <div
                            @click="
                              updateTaskState(t.id, t.attributes.isDone), (t.attributes.isDone = !t.attributes.isDone)
                            "
                            class="tbprTitleSlot"
                          >
                            <div class="tbprTaskStateWrap">
                              <v-checkbox v-model="t.attributes.isDone" color="var(--cMPM)" hide-details></v-checkbox>
                            </div>
                            <div :title="t.attributes.Name" class="tbprTaskTitleWrap">
                              {{ t.attributes.Name }}
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="3" md="4" class="pl-2 md:pl-0">
                          <div class="tbprTaskTimeWrapper">
                            {{ timeOfTask(t) }}
                          </div>
                        </v-col>
                        <v-col class="fxEnd fyCenter" cols="2">
                          <div
                            v-if="t.attributes.Info != null && t.attributes.Info.list.length"
                            class="editTask mr-1 md:mr-5"
                          >
                            <v-btn size="small" @click="fIntoTask(t)" variant="text" class="">
                              <v-progress-circular
                                :size="20"
                                :width="2"
                                color="green"
                                :model-value="tListProg(t.attributes.Info.list)"
                                >{{ t.attributes.Info.list.length }}</v-progress-circular
                              >
                            </v-btn>
                          </div>
                          <div class="editTask mr-1">
                            <v-btn
                              @click="fEditTask(t)"
                              size="small"
                              variant="text"
                              class="text-gray-400"
                              icon="mdi-pencil-circle"
                            ></v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </div>

                    <div class="pl-4 pr-1 fyCenter justify-between addNewTaskQuick rounded-b-lg bg-white pb-2">
                      <v-row no-gutters>
                        <v-col cols="6" class="pr-6 pl-1 -mt-2">
                          <v-text-field
                            class=""
                            v-on:keyup.enter="addQuickT(p, i)"
                            placeholder="Task"
                            v-model="p.QuickTask"
                            variant="underlined"
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="2" class="pr-6 -mt-2">
                          <v-text-field
                            variant="underlined"
                            v-on:keyup.enter="addQuickT(p, i)"
                            class=""
                            ref="startTimeTask"
                            placeholder="Start"
                            v-maska="'##:##'"
                            v-model="p.QuickStart"
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="2" class="pr-6 -mt-2">
                          <v-text-field
                            variant="underlined"
                            v-on:keyup.enter="addQuickT(p, i)"
                            class=""
                            ref="endTimeTask"
                            placeholder="Ende"
                            v-maska="'##:##'"
                            v-model="p.QuickEnd"
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="2" class="fxEnd fyCenter">
                          <v-btn
                            variant="text"
                            color="var(--cMPM)"
                            icon="mdi-plus-circle-outline"
                            class="-mb-4"
                            @click="addQuickT(p, i)"
                            size="small"
                          >
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-dialog class="overlayCont smallOverlay" scrollable v-model="newTaskOv">
        <div class="ovCard small">
          <div class="ovCardTitleWrapper">
            <div class="">
              <div class="ovTitle">Task hinzufügen</div>
            </div>
            <div class="closeBtnWrapper">
              <v-btn @click="newTaskOv = false" color="var(--cMPM)" variant="text" icon="mdi-close-circle"></v-btn>
            </div>
          </div>
          <v-divider class="ovTitleDivider"></v-divider>
          <div class="px-6">
            <task-comp @tSuccess="taskUpdate('newTask')" :task="nTaskActiveProject"></task-comp>
          </div>
        </div>
      </v-dialog>
      <div>
        <project-comp
          v-if="showProject"
          @close="(showProject = false), (showProjectID = null)"
          :showProject="showProject"
          :id="showProjectID"
        />
      </div>
      <v-dialog class="overlayCont smallOverlay" scrollable v-model="editTaskOV">
        <div class="ovCard small">
          <div class="ovCardTitleWrapper">
            <div class="fyCenter">
              <div class="ovTitle">Task bearbeiten</div>
              <div class="ml-4">
                <v-btn
                  variant="plain"
                  class="opacity-100 font-bold"
                  color="var(--cMPM)"
                  @click="addListToTask"
                  size="small"
                >
                  <v-icon start icon="mdi-information-outline"></v-icon>
                  <span class="">Info</span>
                </v-btn>
              </div>
            </div>
            <div class="closeBtnWrapper">
              <v-btn
                @click="(editTaskOV = false), (editTask = {})"
                color="var(--cMPM)"
                variant="text"
                icon="mdi-close-circle"
              ></v-btn>
            </div>
          </div>
          <v-divider class="ovTitleDivider"></v-divider>
          <div class="px-6">
            <task-comp @tSuccess="taskUpdate('editTask')" :task="editTask"></task-comp>
          </div>
        </div>
      </v-dialog>
      <v-dialog class="overlayCont smallOverlay" scrollable v-model="tInfoOV">
        <div class="ovCard small">
          <div class="ovCardTitleWrapper">
            <div class="">
              <div class="ovTitle">Taskinfos</div>
            </div>
            <div class="closeBtnWrapper">
              <v-btn @click="tInfoOV = false" color="var(--cMPM)" variant="text" icon="mdi-close-circle"></v-btn>
            </div>
          </div>
          <v-divider class="ovTitleDivider"></v-divider>
          <div class="px-6">
            <task-info @close="refreshTaskInfo" :cTask="cTaskInfo"></task-info>
          </div>
        </div>
      </v-dialog>
      <v-dialog class="overlayCont smallOverlay" v-model="modalNewProject.open" scrollable>
        <div class="ovCard w-full small">
          <div class="ovCardTitleWrapper px-6 py-3">
            <div class="">
              <div class="ovWTypeInd">
                {{ modalNewProject.typeLabel }}
              </div>
              <div class="ovTitle">
                {{ modalNewProject.date }}
              </div>
            </div>
            <div class="closeBtnWrapper -mt-4">
              <div class="closeBtnWrapper">
                <v-btn
                  @click="(modalNewProject.open = false), (addProTab = null), (nTaskNewProject = {})"
                  color="var(--cMPM)"
                  variant="text"
                  icon="mdi-close-circle"
                ></v-btn>
              </div>
            </div>
          </div>
          <v-divider></v-divider>
          <v-window v-model="addProTab">
            <v-window-item value="one">
              <div class="addNewTaskNewProject">
                <v-card-text class="px-6">
                  <div class="searchWrapper flex">
                    <v-text-field
                      class="rounded-r-none"
                      v-model="modalNewProject.searchText"
                      ref="searchpro"
                      label="Suche"
                    ></v-text-field>
                    <div class="addProjectWrapper">
                      <v-btn
                        variant="plain"
                        size="large"
                        @click="addNewProject"
                        class="addBtn"
                        :class="{ active: modalNewProject.searchText.length > 2 }"
                      >
                        <v-icon icon="mdi-plus-circle-outline"> </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-card-text>
                <div class="px-6">
                  <div class="ovWTypeInd">Projekte</div>
                </div>
                <v-card-text class="relative overflow-y-scroll bg-white px-6" style="height: 300px">
                  <v-list class="bg-white">
                    <v-list-item
                      v-for="(item, i) in searchProjects"
                      :key="i"
                      :value="item"
                      @click="newTasknPro({ id: item.id, Name: item.Name })"
                      prepend-icon="mdi-plus-box"
                      class="bg-gray-200 mb-3 font-semibold rounded-md"
                    >
                      <v-list-item-title class="font-semibold" v-text="item.Name"></v-list-item-title>
                    </v-list-item>
                  </v-list>
                  <div v-if="!searchProjects.length" class="text-center py-6">
                    Keine nicht-aktiven Projekte gefunden.
                  </div>
                </v-card-text>
              </div>
            </v-window-item>
            <v-window-item value="two">
              <div class="addNewTaskNewProject px-6 pt-6 aNewTask-2">
                <task-comp @tSuccess="taskUpdate('newTaskProject')" :task="nTaskNewProject"></task-comp>
              </div>
            </v-window-item>
          </v-window>
        </div>
      </v-dialog>
    </div>
    <div class="w-full loaderFullScreen mw-sm mx-auto fyCenter" v-else>
      <v-progress-linear indeterminate color="#FFF"></v-progress-linear>
    </div>
  </div>
</template>

<script>
import { getUserTasksByDates } from "@/graphql/queries";
import { updateTaskState } from "@/graphql/mutations";
import { createTask, createProject } from "@/graphql/create";
import taskComp from "../components/taskComp.vue";
import taskWorkTypes from "@/composables/workTypeScheme";
import taskScheme from "@/composables/taskScheme";
import formatDate from "@/composables/timeFormat";
import ProjectComp from "./projectComp.vue";
import TaskInfo from "./taskInfo.vue";

export default {
  components: { taskComp, ProjectComp, TaskInfo },
  props: {
    uid: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      tasksByDay: [],
      loading: true,
      modalNewProject: {
        open: false,
        typeLabel: "",
        typeval: "",
        dindex: 0,
        title: "Projekt wählen",
        cProjects: [],
        searchText: "",
      },
      addProTab: null,
      newTaskOv: false,
      editTaskOV: false,
      tInfoOV: false,
      cTaskInfo: {},
      showProjectID: null,
      showProject: false,
      nTaskActiveProject: {},
      nTaskNewProject: {},
      editTask: {},
    };
  },
  watch: {
    newTaskOv(newCont) {
      if (newCont === false) {
        this.nTaskActiveProject = {};
      }
    },
    editTaskOV(newCont) {
      if (newCont === false) {
        this.editTask = {};
      }
    },
    dateChange: {
      deep: true,
      handler() {
        this.loading = true;
        this.$apollo.queries.usersPermissionsUser.refetch();
      },
    },
  },
  computed: {
    dateChange() {
      return {
        sDate: this.$store.state.tasksConfig.sDate,
        eDate: this.$store.state.tasksConfig.s,
      };
    },
    sDate() {
      return this.$store.state.tasksConfig.sDate;
    },
    eDate() {
      return this.$store.state.tasksConfig.eDate;
    },
    dateDiff() {
      return this.$moment(this.eDate).diff(this.$moment(this.sDate), "days");
    },
    twWorkTypes() {
      return taskWorkTypes;
    },
    searchProjects() {
      let tempProjects = this.modalNewProject.cProjects;
      if (this.modalNewProject.searchText != "" && this.modalNewProject.searchText) {
        tempProjects = tempProjects.filter((item) => {
          let Name = item.Name;
          return Name.toUpperCase().includes(this.modalNewProject.searchText.toUpperCase());
        });
      }
      return tempProjects;
    },
  },
  apollo: {
    usersPermissionsUser: {
      query: getUserTasksByDates,
      variables() {
        return {
          id: parseInt(this.uid),
          sDate: this.sDate,
          eDate: this.eDate,
        };
      },
      context: {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt"),
        },
      },
      result(res) {
        let projects = [...res.data.usersPermissionsUser.data.attributes.projects.data];
        this.sortTasks(projects);
      },
      error(graphQLErrors) {
        console.log("errors", graphQLErrors);
      },
    },
  },
  methods: {
    refreshTaskInfo() {
      this.tInfoOV = false;
      this.$apollo.queries.usersPermissionsUser.refetch();
    },
    progCheck(t, ty, m) {
      let c = 0;
      let dc = 0;
      if (ty == "project") {
        t.forEach((s) => {
          c++;
          if (s.attributes.isDone) {
            dc++;
          }
        });
      }
      if (ty == "worktype") {
        t.forEach((p) => {
          p.tasks.forEach((ts) => {
            c++;
            if (ts.attributes.isDone) {
              dc++;
            }
          });
        });
      }
      if (m == "all") {
        return c;
      } else if (m == "isDone") {
        if (c == dc) {
          return true;
        } else {
          return false;
        }
      } else {
        return dc;
      }
    },
    updateTaskState(id, state) {
      let rState = false;
      if (state === false) {
        rState = true;
      }
      let taskInput = {
        ID: id,
        data: {
          isDone: rState,
        },
      };
      this.$apollo
        .mutate({
          mutation: updateTaskState,
          variables: taskInput,
        })
        .then(() => {});
    },
    openModal(type, value, d) {
      if (type == "newProject") {
        let ntS = taskScheme();
        this.nTaskNewProject = ntS;
        this.modalNewProject.typeLabel = value.Name;
        this.modalNewProject.typeval = value.value;
        this.modalNewProject.dindex = d;
        this.nTaskNewProject.aUser = this.uid;
        this.nTaskNewProject.DueDate = this.$moment().add(d, "days").format("YYYY-MM-DD");
        if (value.value == "work") {
          this.modalNewProject.cProjects = this.tasksByDay[d].work.avProjects;
        } else {
          this.modalNewProject.cProjects = this.tasksByDay[d].private.avProjects;
        }
        this.modalNewProject.open = true;
        this.modalNewProject.date = formatDate(this.tasksByDay[d].date, "de");
      }
    },
    newTasknPro(project) {
      this.nTaskNewProject.Project.Name = project.Name;
      this.nTaskNewProject.Project.pid = project.id;
      this.addProTab = "two";
    },
    addProToAv(project) {
      if (this.modalNewProject.typeval == "work") {
        const index = this.tasksByDay[this.modalNewProject.dindex].work.avProjects.findIndex((p) => {
          return p.id === project.id;
        });
        this.tasksByDay[this.modalNewProject.dindex].work.avProjects.splice(index, 1);
        this.tasksByDay[this.modalNewProject.dindex].work.actProjects.push(project);
      } else {
        const index = this.tasksByDay[this.modalNewProject.dindex].private.avProjects.findIndex((p) => {
          return p.id === project.id;
        });
        this.tasksByDay[this.modalNewProject.dindex].private.avProjects.splice(index, 1);
        this.tasksByDay[this.modalNewProject.dindex].private.actProjects.push(project);
      }
      this.modalNewProject.open = false;
    },
    sortTasks(projects) {
      this.tasksByDay = [];
      let dView = [];
      for (let i = 0; i <= this.dateDiff; i++) {
        let cDate = this.$moment(this.sDate).add(i, "days").format("YYYY-MM-DD");
        let dayTemp = {
          date: cDate,
          view: "mdi-expand-all",
          work: {
            avProjects: [],
            actProjects: [],
          },
          private: {
            avProjects: [],
            actProjects: [],
          },
        };
        let prTemp = [...projects];
        for (const p of Object.keys(prTemp)) {
          let aTask = [];
          for (const t of Object.keys(prTemp[p].attributes.tasks.data)) {
            if (prTemp[p].attributes.tasks.data[t].attributes.DueDate == cDate) {
              let dayViewTemp = {
                id: "",
                title: "",
                start: "",
                end: "",
                className: "",
                backgroundColor: "var(--cMPM)",
                extendedProps: {
                  proName: "",
                  isDone: false,
                },
              };
              let Task = JSON.parse(JSON.stringify(prTemp[p].attributes.tasks.data[t]));
              dayViewTemp.id = Task.id;
              dayViewTemp.title = Task.attributes.Name;
              dayViewTemp.start = Task.attributes.DueDate;
              dayViewTemp.end = Task.attributes.DueDate;
              dayViewTemp.extendedProps.isDone = Task.attributes.isDone;
              if (Task.attributes.isDone === true) {
                dayViewTemp.backgroundColor = "green";
                dayViewTemp.className = "isDone";
              }
              if (Task.attributes.scStart != "" && Task.attributes.scStart != null) {
                dayViewTemp.start += "T" + Task.attributes.scStart;
              }
              if (Task.attributes.scEnd != "" && Task.attributes.scEnd != null) {
                dayViewTemp.end += "T" + Task.attributes.scEnd;
              }
              dayViewTemp.extendedProps.proName = Task.attributes.project.data.attributes.Name;
              dView.push(dayViewTemp);
              aTask.push(Task);
            }
          }
          aTask.sort((a, b) => {
            return a.attributes.Order - b.attributes.Order;
          });
          let cProject = {
            id: prTemp[p].id,
            Name: prTemp[p].attributes.Name,
            WorkType: prTemp[p].attributes.WorkType,
            QuickTask: "",
            QuickStart: "",
            QuickEnd: "",
            tasks: aTask,
          };
          if (!cProject.tasks.length) {
            if (prTemp[p].attributes.WorkType == "work") {
              dayTemp.work.avProjects.push(cProject);
            } else {
              dayTemp.private.avProjects.push(cProject);
            }
          } else {
            if (prTemp[p].attributes.WorkType == "work") {
              dayTemp.work.actProjects.push(cProject);
            } else {
              dayTemp.private.actProjects.push(cProject);
            }
          }
        }
        this.tasksByDay.push(dayTemp);
      }
      this.$store.dispatch("setDayEvents", dView);
      this.loading = false;
    },
    acProDay(d, v) {
      console.log(this.tasksByDay);
      if (v == "work") {
        return this.tasksByDay[d].work.actProjects;
      } else {
        return this.tasksByDay[d].private.actProjects;
      }
    },
    createTaskOV(p, d) {
      let ntS = taskScheme();
      ntS.DueDate = this.$moment().add(d, "days").format("YYYY-MM-DD");
      ntS.Project.Name = p.Name;
      ntS.Project.pid = p.id;
      ntS.aUser = this.uid;
      this.nTaskActiveProject = ntS;
      this.newTaskOv = true;
    },
    fEditTask(t) {
      let ntS = taskScheme();
      ntS.uid = t.id;
      ntS.aUser = this.uid;
      ntS.Name = t.attributes.Name;
      ntS.Project = {
        pid: t.attributes.project.data.id,
        Name: t.attributes.project.data.attributes.Name,
      };
      ntS.DueDate = t.attributes.DueDate;
      // ntS.tStart = t.attributes.scStart;
      // ntS.tEnd = t.attributes.scEnd;
      this.editTask = ntS;
      this.editTaskOV = true;
    },
    tListProg(l) {
      let done = 0;
      l.forEach((i) => {
        if (i.isDone === true) {
          done++;
        }
      });
      return (100 / l.length) * done;
    },
    fIntoTask(t) {
      let ntS = {
        id: t.id,
        Info: t.attributes.Info,
        tInfo: t.attributes.tInfo,
      };
      this.cTaskInfo = ntS;
      this.tInfoOV = true;
    },
    taskUpdate(type) {
      this.$apollo.queries.usersPermissionsUser.refetch();
      if (type == "newTask") {
        this.nTaskNewProject = {};
        this.newTaskOv = false;
      }
      if (type == "editTask") {
        this.editTask = {};
        this.editTaskOV = false;
      }
      if (type == "newTaskProject") {
        this.addProTab = null;
        this.nTaskActiveProject = {};
        this.modalNewProject.open = false;
      }
    },
    timeOfTask(t) {
      let timeReturn = "";
      if (t.attributes.scStart != null && t.attributes.scStart != "") {
        timeReturn = this.$moment(t.attributes.DueDate + "T" + t.attributes.scStart).format("HH:mm");
      }
      if (t.attributes.scEnd != null && t.attributes.scEnd != "") {
        timeReturn += " - " + this.$moment(t.attributes.DueDate + "T" + t.attributes.scEnd).format("HH:mm");
      }
      return timeReturn;
    },
    addQuickT(p, d) {
      if (p.QuickTask != "") {
        let nqTask = {
          Name: p.QuickTask,
          aUser: this.uid,
          project: p.id,
          publishedAt: formatDate("", "iso"),
          DueDate: this.$moment().add(d, "days").format("YYYY-MM-DD"),
        };
        if (p.QuickStart != "") {
          nqTask.scStart = p.QuickStart + ":00.000";
        }
        if (p.QuickEnd != "") {
          nqTask.scEnd = p.QuickEnd + ":00.000";
        }
        this.$apollo
          .mutate({
            mutation: createTask,
            context: {
              headers: {
                Authorization: "Bearer " + this.$store.state.user.jwt,
              },
            },
            variables: {
              data: nqTask,
            },
          })
          .then(() => {
            this.$apollo.queries.usersPermissionsUser.refetch();
            p.QuickTask = "";
            p.QuickStart = "";
            p.QuickEnd = "";
          });
      }
    },
    addNewProject() {
      if (this.modalNewProject.searchText != "") {
        let nProject = {
          Name: this.modalNewProject.searchText,
          publishedAt: formatDate("", "iso"),
          WorkType: this.modalNewProject.typeval,
          users: this.$store.state.user.user.id,
        };
        this.$apollo
          .mutate({
            mutation: createProject,
            context: {
              headers: {
                Authorization: "Bearer " + this.$store.state.user.jwt,
              },
            },
            variables: {
              data: nProject,
            },
          })
          .then((res) => {
            this.newTasknPro({ id: res.data.createProject.data.id, Name: res.data.createProject.data.attributes.Name });
          });
      }
    },
    changeView(view, i) {
      if (view == "mdi-expand-all") {
        this.tasksByDay[i].view = "mdi-check";
      } else {
        this.tasksByDay[i].view = "mdi-expand-all";
      }
    },
    checkView(view) {
      if (view == "mdi-expand-all") {
        return "showAllTask";
      } else {
        return "onlyDone";
      }
    },
    addListToTask() {
      let nListTask = {
        type: "sortlist",
        list: [],
      };
      let taskInput = {
        ID: this.editTask.uid,
        data: {
          Info: nListTask,
        },
      };

      this.cTaskInfo = {
        id: this.editTask.uid,
        Info: nListTask,
      };
      this.$apollo
        .mutate({
          mutation: updateTaskState,
          variables: taskInput,
        })
        .then(() => {
          this.$apollo.queries.usersPermissionsUser.refetch();
          this.editTaskOV = false;

          this.tInfoOV = true;
        });
    },
  },
};
</script>

<style>
.onlyDone .isDoneTask,
.onlyDone .addNewTaskQuick {
  display: none;
}
</style>
