<template>
  <div class="name py-24">
    <div class="container mx-auto" v-if="user">
      <list-tasks :uid="user.id" />
    </div>
  </div>
</template>

<script>
import listTasks from "../components/listTasks.vue";

export default {
  components: { listTasks },
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
  },
};
</script>
