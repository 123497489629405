<template>
<div class="px-6 fullScreenOVCont">
    <FullCalendar class="h-full" :options="calendarOptions">
        <template v-slot:eventContent="arg">
            <div :class="{'isDone' : arg.event.extendedProps.isDone}">
                <div v-if="!arg.event.extendedProps.isDone" class="dViewTaskPro -mb-1">
                    {{arg.event.extendedProps.proName}}
                </div>
                <div class="dViewTaskTitle">
                    {{arg.event.title}}
                </div>
            </div>
        </template>
    </FullCalendar>
</div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue3'
import deLocale from '@fullcalendar/core/locales/de';
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list';
export default {
    components: {
        FullCalendar,
    },
    data() {
        return {

        }
    },
    computed: {
        calendarOptions() {
            return {
                plugins: [dayGridPlugin, interactionPlugin, listPlugin],
                initialView: 'listWeek',
                listDayFormat: true,
                locale: deLocale,
                events: this.$store.state.dViewEvents,
                headerToolbar: {
                    start: '', // will normally be on the left. if RTL, will be on the right
                    center: '',
                    end: ''
                }
            }
        },
    },
}
</script>
