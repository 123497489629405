import gql from "graphql-tag";

export const getProjectByID = gql`
  query getProjectByID($id: ID!) {
    project(id: $id) {
      data {
        id
        attributes {
          Name
          WorkType
          Accounts
          Informationen
          Ansprechpartner {
            id
            Name
            Email
            Telefon
          }
        }
      }
    }
  }
`;
export const getUserTasksByDates = gql`
  query getUserTasksByDates($id: ID!, $sDate: Date!, $eDate: Date!) {
    usersPermissionsUser(id: $id) {
      data {
        id
        attributes {
          projects(pagination: { limit: 999 }) {
            data {
              id
              attributes {
                Name
                WorkType
                tasks(pagination: { limit: 999 }, filters: { DueDate: { gte: $sDate, lte: $eDate } }) {
                  data {
                    id
                    attributes {
                      Name
                      isDone
                      DueDate
                      scStart
                      scEnd
                      Order
                      Info
                      tInfo
                      project {
                        data {
                          id
                          attributes {
                            Name
                            WorkType
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getUserProjects = gql`
  query getUserProjects($id: ID!) {
    usersPermissionsUser(id: $id) {
      data {
        id
        attributes {
          projects {
            data {
              id
              attributes {
                Name
              }
            }
          }
        }
      }
    }
  }
`;

export const getUserPwMgmt = gql`
  query getUserPwMgmt($id: ID!) {
    usersPermissionsUser(id: $id) {
      data {
        id
        attributes {
          projects(pagination: { limit: 100 }) {
            data {
              id
              attributes {
                Name
                accMgmt {
                  Link
                  Name
                  User
                  Password
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getProjects = gql`
  query getProjects {
    projects {
      data {
        id
        attributes {
          Name
          WorkType
        }
      }
    }
  }
`;
export const getProjectsFull = gql`
  query getProjectsFull {
    projects {
      data {
        id
        attributes {
          Name
          WorkType
          Accounts
          Ansprechpartner {
            id
            Name
            Telefon
            Email
          }
          Informationen
        }
      }
    }
  }
`;

export const getOpenTasksByUser = gql`
  query getOpenTasksByUser($id: ID!, $today: Date!) {
    tasks(
      pagination: { limit: 100 }
      filters: { DueDate: { lt: $today }, isDone: { eq: false }, aUser: { id: { eq: $id } } }
    ) {
      data {
        id
        attributes {
          Name
          DueDate
          scEnd
          scStart
          project {
            data {
              id
              attributes {
                Name
              }
            }
          }
        }
      }
    }
  }
`;

export const getWishlists = gql`
  query getWishlists {
    wishlists {
      data {
        id
        attributes {
          Category
          wishitems {
            id
            Name
            EstimateDate
            Cost
            Links
            Desc
            Type
            Prio
          }
        }
      }
    }
  }
`;

export const getBirthdays = gql`
  query getBirthdays {
    birthdays(pagination: { limit: 370 }) {
      data {
        id
        attributes {
          Date
          Birthdays
        }
      }
    }
  }
`;

export const getFinaceMonth = gql`
  query getFinaceMonth($m: String!) {
    finances(filters: { mident: { eq: $m } }) {
      data {
        id
        attributes {
          general
          cbalance
        }
      }
    }
  }
`;

export const getFinancesByDates = gql`
  query getFinancesByDates($sDate: Date!, $eDate: Date!) {
    finances(filters: { Date: { gte: $sDate, lte: $eDate } }, pagination: { limit: 370 }) {
      data {
        id
        attributes {
          Name
          Date
          Value
          isExp
          finance_cat {
            data {
              id
              attributes {
                Name
              }
            }
          }
        }
      }
    }
  }
`;

export const getFCats = gql`
  query getFCats {
    financeCats {
      data {
        id
        attributes {
          Name
        }
      }
    }
  }
`;
