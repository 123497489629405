<template>
  <div class="taskFilterWrapper">
    <DatePicker color="gray" is-range v-model="range" mode="date" locale="de" is-expanded />
    <div class="fxEnd mt-4 mb-2">
      <v-btn @click="filterTasks()" class="opacity-100 font-bold" color="var(--cMPM)">
        <v-icon start icon="mdi-filter"></v-icon>
        Filter
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sDate: {
      type: String,
      default: "",
    },
    eDate: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      range: {
        start: this.sDate,
        end: this.eDate,
      },
    };
  },
  methods: {
    filterTasks() {
      let newTasksConfig = {
        sDate: this.$moment(this.range.start).format("YYYY-MM-DD"),
        eDate: this.$moment(this.range.end).format("YYYY-MM-DD"),
      };
      this.$store.dispatch("updateTaskConfig", newTasksConfig);
      this.$emit("close");
    },
  },
};
</script>
