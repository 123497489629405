import gql from 'graphql-tag';


export const createTask = gql `
mutation createTask($data: TaskInput!) {
    createTask(data: $data ) {
      data {
        id
        attributes {
         Name
        }
      }
    }
  }
  `
export const createProject = gql `
  mutation createProject($data: ProjectInput!) {
    createProject(data: $data ) {
      data {
        id
        attributes {
         Name
        }
      }
    }
  }`

export const createBirthday = gql `
  mutation createBirthday($data: BirthdayInput!) {
    createBirthday(data: $data) {
      data {
        id
        attributes {
          Date
          Birthdays
        }
      }
    }
  }`

export const createFiTask = gql `
  mutation createFiTask($data: FinanceInput!) {
    createFinance(data: $data ) {
      data {
        id
      }
  }
}`